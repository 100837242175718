import { useNavigate } from 'react-router-dom'

import { Box, Container, Link, Typography } from '@mui/material'

import { Game } from 'models/gameModels'

import { GameType } from 'enums/GameTypeEnum'

import { HOME_PAGE } from '../../assets/routes'
import { styles } from './BreadcrumbMenu.styles'

export const TEST_ID = 'breadcrumb-menu'

export interface Props {
	game?: Game
	gameTypeEnum?: GameType
}

const BreadcrumbMenu = ({ game, gameTypeEnum }: Props) => {
	const navigate = useNavigate()
	const backButtonClicked = () => {
		window.scrollTo(0, 0)
		navigate(HOME_PAGE)
	}

	return (
		<>
			<Container maxWidth={false} sx={styles.BreadcrumbContainer}>
				<Link
					data-testid={`${TEST_ID}-back-arrow-button`}
					onClick={backButtonClicked}
					sx={{
						color: 'black',
						opacity: '60%',
						textDecoration: 'none',
						':hover': {
							textDecoration: 'underline',
						},
					}}
				>
					<Typography>All Games</Typography>
				</Link>
				<Typography>/</Typography>
				<Box sx={styles.GameNameContainer}>
					<Box
						data-testid={`${TEST_ID}-game-name-info`}
						sx={styles.GameNameText}
					>
						{(game && (
							<Typography>Game Title: &nbsp;</Typography>
						)) ||
							null}
						<Typography>
							<b>
								{game
									? game.gameTitle
									: (gameTypeEnum as GameType).label}
							</b>
						</Typography>
					</Box>
				</Box>
			</Container>
		</>
	)
}

export default BreadcrumbMenu
