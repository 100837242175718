import { useCallback } from 'react'

export const useInputBehavior = (onChangeValue: (val: string) => void) => {
	const handleChange = useCallback(
		(val: string) => {
			onChangeValue(val)
		},
		[onChangeValue]
	)

	const handleBlur = useCallback(
		(val: string) => {
			const trimmedValue = val && val.trim()
			onChangeValue(trimmedValue)
		},
		[onChangeValue]
	)

	return { handleChange, handleBlur }
}
