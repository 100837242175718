import { SxProps } from '@mui/material'

export interface IStyles {
	[key: string]: SxProps
}

export const commonStyles: IStyles = {
	container: {
		padding: '2.8rem 4.8rem',
	},
	gridContainer: {},
	promptContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '2.2rem',
	},
	scenarioContainer: {
		display: 'flex',
		gap: '6.9rem',
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		mb: '6.9rem',
	},
	circularProgress: {
		padding: '1rem',
	},
}
