import React from 'react'

import { Typography } from '@mui/material'

import { typography } from 'theme/typography'

const Section2Title = () => {
	return (
		<Typography sx={typography.overline} data-testid={'section-2-title'}>
			2. FACILITATION TECHNIQUE
		</Typography>
	)
}

export default Section2Title
