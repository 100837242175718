import React, { useState } from 'react'

import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { DressUpPromptResponse } from 'models/ideaModels'

import { useToastContext } from 'contexts/ToastProvider'

import PromptCarouselComponent from 'pages/RoundRobin/components/Carousel'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section2.styles'

export const TEST_ID = 'dress-up-section2'

export type Props = {
	createPrompt: () => void
	dressUpPrompt?: DressUpPromptResponse
	loading: boolean
	reloadIdeas: (updatedTraits: string, companyIndex: number) => Promise<void>
}

const buildResponseItem = (solution: string) => {
	const stringSol = solution
		.split(/(\d.)/)
		?.splice(1)
		.filter((x) => isNaN(parseInt(x)))
	return (
		<Box bgcolor={'rgba(231, 248, 255, 1)'} padding={'1rem'}>
			<ol data-testid="gen-ai-response-list">
				{stringSol.map((el: string, i: number) => (
					<Typography
						fontSize={16}
						color="black"
						component="li"
						sx={{ opacity: 0.87 }}
						key={`ai-solution-${i}`}
						variant="body1"
						style={{
							margin: '10px 0 10px 0',
						}}
					>
						{el}
					</Typography>
				))}
			</ol>
		</Box>
	)
}
const Section2 = ({ dressUpPrompt, ...props }: Props) => {
	const { showAlert } = useToastContext()
	const [businessComparisonIndex, setBusinessComparisonIndex] = useState(0)
	const [editPromptMode, setEditPromptMode] = useState(false)
	const [isGenAiLoading, setIsGenAiLoading] = useState(false)

	const onClickRefresh = () => {
		setBusinessComparisonIndex(0)
		props.createPrompt()
	}

	const [temporalPromptsEditMode, setTemporalPrompts] = useState<string[]>([])
	const setPromptValue = (value: string) => {
		const newTemporalPrompts = [...temporalPromptsEditMode]
		newTemporalPrompts[businessComparisonIndex] = value
		setTemporalPrompts(newTemporalPrompts)
	}
	const currentPrompts =
		dressUpPrompt?.data.companies.map((item) => item.traits) ?? []

	const onClickEditPrompt = () => {
		setTemporalPrompts(currentPrompts)
		setEditPromptMode(true)
	}

	const onClickCancelEditPrompt = () => {
		setEditPromptMode(false)
	}

	const onClickSaveEditPrompt = async () => {
		const lastEditedIdea = document.getElementsByTagName('textarea')
		if (dressUpPrompt && lastEditedIdea && lastEditedIdea.length >= 1) {
			if (
				dressUpPrompt?.data.companies[businessComparisonIndex]
					.traits !== lastEditedIdea[0].value
			) {
				setIsGenAiLoading(true)

				try {
					await props.reloadIdeas(
						lastEditedIdea[0].value,
						businessComparisonIndex
					)
					setEditPromptMode(false)

					if (showAlert) {
						showAlert({
							objectType: 'editPrompt',
							severity: ToastSeverity.SUCCESS,
						})
					}
				} catch (error) {
					if (showAlert) {
						showAlert({
							objectType: 'defaultError',
							severity: ToastSeverity.ERROR,
						})
					}
				} finally {
					setIsGenAiLoading(false)
				}
			}
		}
	}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
					paddingY={{ xs: '0', sm: '1rem' }}
				>
					<Box
						display={'flex'}
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
						justifyContent={'space-between'}
						alignItems={{
							xs: 'flex-start',
							sm: 'center',
						}}
						gap={'1rem'}
					>
						<Typography
							data-testid={`${TEST_ID}-user-directions-verbiage`}
						>
							While parity comes from looking at competitors;
							innovation comes from exploring best practices
							across different industries. Consider what these
							cross-industry companies are known for to inspire
							new approaches to this problem.
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'center'}
							alignItems={'center'}
							gap={'1rem'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={refreshIcon}
								text="REFRESH"
								data-testid={`${TEST_ID}__refresh-button`}
								onClickAction={onClickRefresh}
							/>
						</Box>
					</Box>
					<Box marginLeft="2rem" marginRight="2rem">
						{props.loading ? (
							<CircularProgress
								color="secondary"
								sx={commonStyles.circularProgress}
							/>
						) : (
							<>
								<Box sx={styles.carouselWrapper}>
									<Box
										flexDirection={{
											xs: 'column',
											sm: 'row',
										}}
										className="button-wrapper"
									>
										{dressUpPrompt?.data.companies.map(
											(item, index) => (
												<React.Fragment key={index}>
													<Button
														data-testid={`${TEST_ID}__business-comparison-${index}`}
														className={
															index ===
															businessComparisonIndex
																? 'selected'
																: ''
														}
														onClick={() =>
															setBusinessComparisonIndex(
																index
															)
														}
														disabled={
															editPromptMode
														}
													>
														{item.name}
													</Button>
													{index <
														dressUpPrompt.data
															.companies.length -
															1 && (
														<Divider
															orientation="vertical"
															variant="middle"
															flexItem={true}
														/>
													)}
												</React.Fragment>
											)
										)}
									</Box>
								</Box>
								<Box
									data-testid={`${TEST_ID}__business-comparison-traits`}
								>
									{dressUpPrompt && (
										<PromptCarouselComponent
											disabledArrows={editPromptMode}
											setCurrentIndex={
												setBusinessComparisonIndex
											}
											currentIndex={
												businessComparisonIndex
											}
											currentPrompts={dressUpPrompt.data.companies.map(
												(item) => item.traits
											)}
											promptsEditMode={
												temporalPromptsEditMode
											}
											editable={editPromptMode}
											setPromptValue={setPromptValue}
											names={dressUpPrompt.data.companies.map(
												(item) => item.name
											)}
											sxCardOverrides={{
												backgroundColor:
													'rgba(231, 248, 255, 1)',
											}}
										/>
									)}
								</Box>
							</>
						)}
					</Box>
					{editPromptMode ? (
						<Box
							display="flex"
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							gap="2rem"
						>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={async () => {
									setIsGenAiLoading(true)
									await onClickSaveEditPrompt()
									setIsGenAiLoading(false)
								}}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEditPrompt}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Box>
					) : (
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'end'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT"
								data-testid={`${TEST_ID}-edit-prompt`}
								onClickAction={onClickEditPrompt}
							/>
						</Box>
					)}
					<Typography data-testid={`${TEST_ID}-genai-idea-dropdown`}>
						{brainstormText.global.ideasDropdown}
					</Typography>
					<GeneratedExampleDropdown
						gameTypeEnum={GAME_TYPE.DRESS_UP}
						headerText={''}
						isLoading={isGenAiLoading}
						customResponse={buildResponseItem(
							dressUpPrompt?.data?.companies?.[
								businessComparisonIndex
							]?.advices ?? ''
						)}
					/>
				</Box>
			</Box>
		</>
	)
}
export default Section2
