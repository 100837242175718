export const DisclosureOfInfo = () => {
	return (
		<div id="disclosureOfInfo" data-testid="disclosureOfInfo">
			<p>
				<b>Disclosure of your information</b>
			</p>
			<p>
				You agree that we have the right to share your personal
				information with:
				<ul>
					<li>
						Other Brainstorm users (e.g. when you invite others to
						submit ideas to custom platform pages you or others have
						created);
					</li>
					<li>
						Any member of our group, which means our subsidiaries.
					</li>
					<li>Selected third parties including:</li>
					<ul>
						<li>
							service providers who act as a data processor of
							Slalom who are subject to contractual obligations to
							implement technical and organizational security
							measures to safeguard the personal data and to
							process the personal data only as instructed. These
							third-party service providers assist us in assessing
							our information security controls, maintaining
							Brainstorm, analyzing Brainstorm traffic, and in
							providing services and information to us and to
							users of Brainstorm;
						</li>
						<li>
							analytics and search engine providers that assist us
							in the improvement and optimization of Brainstorm;
						</li>
					</ul>
				</ul>
				We will also disclose your personal information to third
				parties:
				<ul>
					<li>
						In the event that we sell or buy any business or assets,
						in which case we will disclose your personal data to the
						prospective seller or buyer of such business or assets.
					</li>
					<li>
						If Slalom, Inc. or its subsidiaries, or substantially
						all of the assets of any of the foregoing, is acquired
						by a third party, in which case personal data held by it
						will be one of the transferred assets.
					</li>
					<li>
						If we are under a duty to disclose or share your
						personal data in order to comply with any legal
						obligation, or in order to enforce or apply other
						agreements; or to protect the rights, property, or
						safety of Slalom, Inc. or its subsidiaries, our
						employees, customers, or others.
					</li>
				</ul>
			</p>
		</div>
	)
}

export const CrossBorderDisclosures = () => {
	return (
		<div id="crossBorderDisclosures" data-testid="crossBorderDisclosures">
			<p>
				<b>Cross-Border Disclosures</b>
			</p>
			<p>
				Your personal data may be disclosed to employees,
				representatives, or other third parties operating outside of the
				country you are based who work for, or are engaged by us or our
				subsidiaries in other countries, including the USA and in other
				countries that we operate, and to our service providers who may
				be located outside of the country you are based, including the
				USA and in other countries that we operate. We note that these
				persons and third parties may also store, transfer or access
				personal data outside of the country you are based.
			</p>
			<p>
				In disclosing your personal data overseas, we will take such
				steps as are reasonable in the circumstances to require that
				overseas recipients protect your personal data in accordance
				with applicable laws.
			</p>
		</div>
	)
}

export const EEADisclosures = () => {
	return (
		<div id="eeaDisclosures" data-testid="eeaDisclosures">
			<p>
				<b>EEA+ Supplemental Data Protection Law Disclosures</b>
			</p>
			<p>
				Slalom provides to residents of the European Economic Area, the
				United Kingdom and Switzerland (EEA+) the following disclosures
				to supplement the Brainstorm Privacy Policy.
			</p>
			<p>
				<u>Data Controller</u>: The data controller is Slalom, Inc. with
				the contact information specified herein.
			</p>
			<p>
				<u>
					Legal Bases for Processing under European Union and United
					Kingdom law
				</u>
				: Legal bases for the processing of your personal data by Slalom
				are: (i) the contract on the use of Brainstorm concluded with
				you (Art. 6 (1) lit. b GDPR) for personal data actively provided
				by you through Brainstorm, (ii) our legitimate interests (Art. 6
				(1) lit. f GDPR) for personal data passively collected through
				Brainstorm which are the following: to monitor and maintain the
				performance of Brainstorm and to analyze trends, usage and
				activities in connection with Brainstorm, (iii) our legitimate
				interests (Art. 6 (1) lit. f GDPR) for the transfer of your
				personal data within the group of companies which are the
				following: for internal administrative and support purposes
				(access is limited to colleagues with a need to know), (iv)
				compliance with a legal obligation to which Slalom is subject
				(Art. 6 (1) lit. c GDPR) for the transmission of personal data
				to law enforcement agencies, governmental authorities, legal
				counsel and external consultants or legitimate interests, such
				as exercise or defense of legal claims, (v) our legitimate
				interests (Art. 6 (1) lit. b GDPR) for the transfer of your
				personal data in connection with a transfer of all or part of
				our organization or assets which are the following: the orderly
				transition of all or part of our business, (vi) our legitimate
				interests to protect and defend the rights or property of us or
				third parties, including enforcing agreements, policies and
				terms of use, and in an emergency including to protect the
				safety of our employees or any person which are the following:
				to protect the property, rights, and safety of any person and to
				prevent fraud.
			</p>
			<p>
				<u>Recipients in Third Countries</u>: The personal data that we
				collect or receive about you may be transferred to and processed
				by recipients that are located inside or outside the EEA+ and
				which are not recognized from an EEA+ law perspective as
				providing for an adequate level of data protection. These third
				parties are generally cloud hosting and internet service
				providers, operating systems and platform providers, and certain{' '}
				<a href="https://www.slalom.com/us/en/who-we-are/partners">
					technology partners
				</a>{' '}
				including AWS, Microsoft, and MixPanel. When interacting with
				Brainstorm or contacting Slalom, Inc., you transfer personal
				data to the United States, and Slalom, Inc. may transfer your
				personal data to the UK, Australia, Japan, Canada, Germany,
				Switzerland, Ireland, Mexico, and New Zealand. Transfers from
				Switzerland to the UK, Germany, Canada, New Zealand, and Ireland
				do not require additional safeguards. Transfers from Switzerland
				to Australia, Japan, Mexico and the US are based on your
				consent. To the extent your personal data are transferred to
				countries that do not provide for an adequate level of data
				protection from an EU or UK law perspective, we will base the
				respective transfer on appropriate safeguards (Art. 46 GDPR/UK
				GDPR), such as standard data protection clauses adopted by the
				European Commission. You can ask for a copy of such appropriate
				safeguards by contacting us as set out in the contact section.
			</p>
			<p>
				<u>Period of Data Storage</u>: Your personal data will be
				retained for as long as necessary to provide you with the
				services requested, which in most cases does not exceed 5 years.
				When Slalom no longer needs to use your personal data to comply
				with contractual or statutory obligations, we will remove it
				from our systems and records and/or take steps to properly
				anonymize it so that you can no longer be identified from it,
				unless we need to keep your information, including personal
				data, for tax, audit, and legal compliance for a legally
				prescribed time period thereafter, or if we need it to preserve
				evidence within statutes of limitation.
			</p>
			<p>
				<u>Data Subject Rights</u>: Under applicable law, you may have
				the following rights in relation to your personal data. These
				may be limited under applicable data protection law.
			</p>
			<p>
				(i) Right to request from us access to personal data: You have
				the right to confirm with us whether your personal information
				is processed, and if it is, to request access to that personal
				information including the categories of personal information
				processed, the purpose of the processing and the recipients or
				categories of recipients. You have the right to obtain a copy of
				the personal data undergoing processing. However, this is not an
				absolute right and the interests of other individuals may
				restrict your right of access.
			</p>
			<p>
				(ii) Right to rectification: You have the right to obtain from
				us rectification of inaccurate or incomplete personal
				information concerning you. Depending on the purposes of the
				processing, you may have the right to have incomplete personal
				data completed, including by means of providing a supplementary
				statement.
			</p>
			<p>
				(iii) Right to erasure of your personal data (right to be
				forgotten): You have the right to ask us to erase personal data
				concerning you.
			</p>
			<p>
				(iv) Right to restriction of processing: In limited
				circumstances, you have the right to request that we restrict
				processing of your personal data.
			</p>
			<p>
				(v) Right to data portability: You may have the right to receive
				personal data concerning you, which you have provided to us, in
				a structured, commonly used and machine-readable format and you
				may have the right to transmit that personal data to another
				entity without hindrance from us.
			</p>
			<p>
				<b>
					(vi) Right to object: Under certain circumstances you may
					have the right to object, on grounds relating to your
					particular situation, at any time to the processing of your
					personal data, including profiling, by us and we can be
					required to no longer process your personal data.
				</b>
			</p>
			<p>
				<b>
					Moreover, if your personal data is processed for direct
					marketing purposes, you have the right to object at any time
					to the processing of personal data concerning you for such
					marketing, which includes profiling to the extent that it is
					related to such direct marketing. In this case your personal
					data will no longer be processed for such purposes by us.
				</b>
			</p>
			<p>
				Depending on your place of residence, you may also have the
				right to lodge a complaint with a supervisory authority. If you
				have declared your consent for any personal data processing
				activities, you can withdraw this consent at any time with
				future effect. Such a withdrawal will not affect the lawfulness
				of the processing prior to the consent withdrawal. You can
				exercise your rights by contacting us as set out in the Contact
				and Complaints section.
			</p>
			<p>
				<u>Failure to Provide Personal Data</u>: Where we are required
				by law to collect your personal data, or we need to collect your
				personal data under the terms of a contract we have with you,
				and you fail to provide that personal data when we request it,
				we may not be able to perform the contract we have or are trying
				to enter into with you. This may apply where you do not provide
				the personal data we need in order to provide the services you
				have requested from us. In this case, we may have to cancel the
				provision of the relevant services to you, in which case we will
				notify you.
			</p>
			<p>
				For your information:
				<ul>
					<li>
						inquiries relating to our European business are
						generally handled by Slalom Consulting Limited, a
						company incorporated and registered in England and Wales
						with company number 08874662 whose registered office is
						at 2 London Bridge, 2nd Floor East, London, SE1 9RA,
						United Kingdom;
					</li>
					<li>
						inquiries relating to our Canadian business are
						generally handled by Slalom Consulting ULC, a Canadian
						unlimited liability company formed under the laws of
						British Columbia whose registered office is at 745
						Thurlow Street, Suite 2400, Vancouver, BC V6E 0C5,
						Canada;
					</li>
					<li>
						inquiries relating to our U.S. and Mexican business are
						generally handled by Slalom, Inc., a company
						incorporated under the laws of the State of Delaware and
						based at 255 S. King Street, Suite 1800, Seattle, WA
						98104, USA;
					</li>
					<li>
						inquiries relating to our Japanese business are
						generally handled by Slalom Japan Co., Ltd., a Japanese
						join stock company whose registered office is 7F Atago
						Green Hills Mori Tower 2 Chrome 5-1 Atago, Minato-ku
						Tokyo 105-6207, Japan;
					</li>
					<li>
						inquiries relating to our Australian business are
						generally handled by Slalom Australia Pty Ltd a company
						incorporated and registered in Australia with company
						number 635 861 004 whose registered office is at Level
						17, 100 Queen Street, Melbourne Victoria 30000,
						Australia;
					</li>
					<li>
						inquiries relating to our New Zealand business are
						generally handled by Slalom New Zealand Ltd a company
						incorporated and registered in New Zealand with company
						number 8244300 whose registered office is at Level 22
						Floor, PWC Tower, 15 Customs Street West, Auckland
						Central, Auckland 1010, New Zealand;
					</li>
				</ul>
				unless we notify you otherwise.
			</p>
		</div>
	)
}

export const JapanDisclosures = () => {
	return (
		<div id="japanDisclosures" data-testid="japanDisclosures">
			<p>
				<b>Japan Supplemental Data Protection Law Disclosures</b>
			</p>
			<p>
				<b>1. Details of relevant Slalom entities</b>
				<br />
				Address and the name of the company representatives of Slalom
				entities that regularly process personal information of
				individuals in Japan are as follows:
			</p>
			<p>
				Slalom, Inc.
				<br />
				255 S. King Street, Suite 1800, Seattle, WA 98104
				<br />
				Company representative: Christopher Burger, Vice President,
				Information Security & Governance,{' '}
				<a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.{' '}
			</p>
			<p>
				Slalom Japan Co., Ltd. 7F Atago Green Hills Mori Tower 2 Chome
				5-1 Atago, Minato-ku, Tokyo 105-6207, Japan <br />
				Company representative: Ko Asami, General Manager Japan,{' '}
				<a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.{' '}
			</p>
			<p>
				For details of other entities, please contact us by email
				addressed at{' '}
				<a href="mailto:privacy@slalom.com">privacy@slalom.com</a> or by
				mail Slalom Consulting, Privacy Office, 255 S. King Street,
				Suite 1800, Seattle, WA 98104.
			</p>
			<p>
				<b>2. Joint use of personal data within Slalom group</b>
				<br />
				We will share your personal data described in this policy with
				the Slalom entities (please see a list of the Slalom entities
				below). Those Slalom entities will use the personal data for the
				purposes set out in the &quot;Uses made of the information&quot;
				section. The entity which is responsible for management of
				personal data is Slalom K.K. specified above.
			</p>
			<p>
				<b>Slalom entity list</b>
				<ul>
					<li>
						Slalom, Inc. (United States - 255 S. King Street, Suite
						1800, Seattle, WA 98104)
					</li>
					<li>
						Slalom Consulting ULC (Canada - 745 Thurlow Street,
						Suite 2400, Vancouver, BC V6E 0C5)
					</li>
					<li>
						Slalom Consulting Limited (United Kingdom - 2 London
						Bridge, 2nd Floor East, London SE1 9RA)
					</li>
					<li>
						Slalom GmbH (Germany - Brienner Str. 34a-d, 80333
						München)
					</li>
					<li>
						Slalom Technology and Management Solutions Limited
						(Ireland - 6th Floor, South Bank House, Barrow Street,
						Dublin 4 D04 TR29)
					</li>
					<li>
						Slalom Netherlands B.V. (Netherlands - Singel 250, 1016
						AB, Amsterdam)
					</li>
					<li>
						Slalom Switzerland GmbH (Switzerland - Schuetzengasse 1,
						Zuerich 8001, Switzerland)
					</li>
					<li>
						Slalom Australia Pty Ltd. (Australia - Level 17, 100
						Queen Street, Melbourne Victoria 3000)
					</li>
					<li>
						Slalom New Zealand Limited (New Zealand - Level 22
						Floor, PWC Tower, 15 Customs Street West, Auckland
						Central, Auckland 1010)
					</li>
					<li>
						Slalom Japan Co., Ltd. (Japan - 7F Atago Green Hills
						Mori Tower 2 Chome 5-1 Atago, Minato-ku Tokyo Japan
						105-6207)
					</li>
					<li>
						Slalom Consulting Singapore Pte. Ltd. (Singapore – 9
						Raffles Place, #32-00, Republic Plaza, 048619)
					</li>
					<li>
						Slalom Limited (Republic of Korea - Tower 1 Gran Seoul,
						7th Floor 33 Jong-ro, Jongro-Gu Seoul 03159)
					</li>
					<li>
						Slalom Consulting Mexico S. de R.L. de C.V. (Mexico –
						Lago Alberto 442, 4th Floor, Parques Plaza, Col.
						Anáhuac, Miguel Hidalgo, 11320, Ciudad de Mexico
					</li>
				</ul>
			</p>
		</div>
	)
}

export const CaliforniaPrivacyNotice = () => {
	return (
		<div id="californiaPrivacyNotice" data-testid="californiaPrivacyNotice">
			<p>
				<b>
					California Consumer Privacy Act &quot;At-Collection&quot;
					Notice
				</b>
			</p>
			<p>
				In this California Consumer Privacy Act (&quot;
				<b>CCPA</b>&quot;) Notice at Collection, we are addressing
				disclosure requirements under California Civil Code §1798.100
				and California Attorney General Regulations at or before the
				point of online collection with respect to information collected
				by Slalom from California residents when California residents
				use Brainstorm.{' '}
				<b>
					This CCPA Notice At Collection does <u>not</u> cover any
					personal data we collect offline or through other online
					sites (such as our website,{' '}
					<a href="https://www.slalom.com/">www.slalom.com)</a>
				</b>
				.
			</p>
			<p>
				We collect the following categories of personal information
				about consumers and for the following business or commercial
				purposes:
			</p>
			<p>
				Information you provide when you input a prompt into Brainstorm.
				<ul>
					<li>
						Identifiers such as a real name, unique personal
						identifier, online identifier, Internet Protocol
						address, email address, or other similar identifiers.
					</li>
					<li>
						Any information that identifies, relates to, describes,
						or is capable of being associated with, a particular
						individual, including, but not limited to, his or her
						name, telephone number, education, employment,
						employment history, but excluding publicly available
						information that is lawfully made available to the
						general public from federal, state, or local government
						records.
					</li>
					<li>
						Internet or other electronic network activity
						information, including, but not limited to, browsing
						history, search history, and information regarding a
						consumer’s interaction with an internet website
						application, or advertisement.
					</li>
					<li>Geolocation data.</li>
					<li>Audio, electronic, visual, or similar information.</li>
					<li>Professional or employment-related information.</li>
					<li>
						Education information, defined as information that is
						not publicly available personally identifiable
						information as defined in the Family Educational Rights
						and Privacy Act (20 U.S.C. Sec. 1232g; 34 C.F.R. Part
						99).
					</li>
					<li>
						Inferences drawn from any of the information identified
						in this subdivision to create a profile about a consumer
						reflecting the consumer’s preferences, characteristics,
						psychological trends, predispositions, behavior,
						attitudes, intelligence, abilities, and aptitudes.
					</li>
				</ul>
				Providing such personal information is voluntary. However,
				without providing some personal information, we will not be able
				to provide you with information or services you request from us,
				if any. We use such personal information:
				<ul>
					<li>
						to carry out our obligations arising from any contracts
						entered into between you and us and to provide you with
						the information, products and services that you request
						from us;
					</li>
					<li>
						to provide you with information about other goods and
						services we offer that are similar to those that you
						have already purchased or inquired about;
					</li>
					<li>
						to provide you, or permit selected third parties to
						provide you, with information about goods or services we
						feel may interest you. We will only contact you by
						electronic means (e-mail or SMS) with information about
						goods and services similar to those which were the
						subject of a previous sale or negotiations of a sale to
						you, or where you have consented to this, or where we
						can otherwise lawfully do so.
					</li>
					<li>to notify you about changes to our service;</li>
					<li>
						to ensure that content from Brainstorm is presented in
						the most effective manner for you and for your computer.
					</li>
				</ul>
				We do not sell your personal information or share it for
				cross‐context behavioral advertising.
			</p>
			<p>
				Your personal information will be retained for as long as
				necessary to provide you with the services requested, which in
				most cases does not exceed 5 years. When Slalom no longer needs
				to use your personal data to comply with contractual or
				statutory obligations, we will remove it from our systems and
				records and/or take steps to properly anonymize it so that you
				can no longer be identified from it, unless we need to keep your
				information, including personal data, for tax, audit, and legal
				compliance for a legally prescribed time period thereafter, or
				if we need it to preserve evidence within statutes of
				limitation.
			</p>
			<p>
				The CCPA Privacy Policy, is available at{' '}
				<a href="https://www.slalom.com/us/en/legal/ccpa-policy">
					https://www.slalom.com/us/en/legal/ccpa-policy
				</a>
			</p>
		</div>
	)
}

export const MexicoDisclosures = () => {
	return (
		<div id="mexicoDisclosures" data-testid="mexicoDisclosures">
			<p>
				<b>Mexico Supplemental Data Protection Law Disclosures</b>
			</p>
			<p>
				<b>1. Your rights in connection with personal information</b>
				<ul>
					<li>
						<u>Right of access</u>
						<br />
						You may have the right to confirm with us whether your
						personal information is processed, and if it is, to
						request access to that personal information including
						the categories of personal information processed and the
						purpose of the processing.
					</li>
					<li>
						<u>Right to rectification</u>
						<br />
						You may have the right to rectify inaccurate or
						incomplete personal information concerning you.
					</li>
					<li>
						<u>Right of cancelation</u>
						<br />
						You may have the right to ask us to cancel personal
						information concerning you and we will assess such
						request in accordance with the relevant laws.
					</li>
					<li>
						<u>Right of opposition</u>
						<br />
						Under certain circumstances you may have the right to
						oppose to our processing of your personal data and we
						will assess such request in accordance with the relevant
						laws.
					</li>
					<li>
						<u>Right to revoke/withdraw your consent</u>
						<br />
						You have the right to withdraw your consent granted to
						us for processing your personal information and we will
						assess such request in accordance with the relevant
						laws.
					</li>
				</ul>
				To exercise any of the abovementioned rights, or to submit
				questions or complaints please contact us via our email address
				at <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
			</p>
		</div>
	)
}
