export const styles = {
	body1: { color: 'black', fontSize: '16px' },
	closeContainer: {
		justifyContent: 'flex-end',
	},
	dialog: {
		backdropFilter: 'blur(16px)',
		width: { xs: '100%', m: '531px' },
		' .MuiDialog-container': {
			alignItems: { xs: 'end', sm: 'center' },
			width: '100%',
		},
		' .MuiPaper-root': {
			alignItems: 'center',
			borderRadius: { xs: '16px 16px 0 0', sm: '32px' },
			boxSizing: 'border-box',
			gap: '32px',
			margin: { xs: 0 },
			padding: { xs: '16px 32px 32px 32px', sm: '32px' },
			maxWidth: { xs: '100%', sm: '531px', lg: '653px' },
		},
	},
	dialogActions: {
		maxWidth: { xs: '310px', md: '314px' },
		width: '100%',
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		padding: 0,
		textAlign: 'center',
	},
	title: {
		fontSize: '20px',
		padding: 0,
	},
	textContainer: {
		textAlign: 'start',
	},
}
