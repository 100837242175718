import React, { ReactNode, createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthProvider'

// import { HOME_PAGE } from 'assets/routes'
import { TimeoutModal } from 'components/TimeoutModal/TimeoutModal'

import { HOME_PAGE } from 'assets/routes'

interface TimeoutContextType {
	handleLogout: () => void
	lastActivity: number
	resetTimer: () => void
	setLastActivity: (time: number) => void
	setShowTimeout: (show: boolean) => void
	showTimeout: boolean
}

interface TimeoutProviderProps {
	children: ReactNode
	timeoutDuration: number
}

const TimeoutContext = createContext<TimeoutContextType | undefined>(undefined)

export const TimeoutProvider: React.FC<TimeoutProviderProps> = ({
	children,
	timeoutDuration,
}) => {
	const [showTimeout, setShowTimeout] = useState(false)
	const [lastActivity, setLastActivity] = useState(Date.now())

	const { user, userSignOut } = useAuth()
	const navigate = useNavigate()

	const handleLogout = async () => {
		setShowTimeout(false)
		resetTimer()
		userSignOut()
		navigate(HOME_PAGE)
	}

	const resetTimer = () => {
		setLastActivity(Date.now())
		setShowTimeout(false)
	}

	return (
		<TimeoutContext.Provider
			value={{
				showTimeout,
				resetTimer,
				handleLogout,
				lastActivity,
				setLastActivity,
				setShowTimeout,
			}}
		>
			{children}
			{user.session && <TimeoutModal timeoutDuration={timeoutDuration} />}
		</TimeoutContext.Provider>
	)
}

export const useActivityTimeout = () => {
	const context = useContext(TimeoutContext)
	if (!context) {
		throw new Error('useTimeout must be used within a TimeoutProvider')
	}
	return context
}
