import React, { useState } from 'react'

import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useToastContext } from 'contexts/ToastProvider'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/Pencil.svg'
import arrowDown from 'assets/images/arrowDown.svg'
import arrowUp from 'assets/images/arrowUp.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { commonStyles } from 'styles/common.styles'

import PromptCarouselComponent from '../Carousel'
import { styles } from './Section2.styles'

export const TEST_ID = 'round-robin-section2'

export type Props = {
	changePrompts: (newValues: string[]) => void
	createPrompt: () => void
	currentPrompts: string[]
	currentResponse: string
	loading: boolean
}

const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [showAIexampleIdea, setShowAIexampleIdea] = useState(false)
	const [currentIndex, setCurrentIndex] = useState<number>(0)
	const [editPromptsMode, setEditPromptsMode] = useState(false)
	const [temporalPromptsEditMode, setTemporalPrompts] = useState<string[]>([])

	const onClickRefresh = () => {
		setShowAIexampleIdea(false)
		props.createPrompt()
	}

	const onClickEdit = () => {
		setTemporalPrompts(props.currentPrompts)
		setEditPromptsMode(true)
	}

	const onClickCancelEdit = () => {
		setEditPromptsMode(false)
	}

	const onClickSaveEdit = () => {
		props.changePrompts(temporalPromptsEditMode)
		setShowAIexampleIdea(false)
		setEditPromptsMode(false)
		if (showAlert) {
			showAlert({
				objectType: 'editPrompt',
				severity: ToastSeverity.SUCCESS,
			})
		}
	}

	const setPromptValue = (value: string) => {
		const newTemporalPrompts = [...temporalPromptsEditMode]
		newTemporalPrompts[currentIndex] = value
		setTemporalPrompts(newTemporalPrompts)
	}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
					paddingY={{ xs: '0', sm: '1rem' }}
				>
					<Box
						display={'flex'}
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
						justifyContent={'space-between'}
						alignItems={{
							xs: 'flex-start',
							sm: 'center',
						}}
						gap={'1rem'}
					>
						<Typography>
							Review the following sections as conversation and
							research starters to refine and iterate on your
							initial idea.
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'center'}
							alignItems={'center'}
							gap={'1rem'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={refreshIcon}
								text="REFRESH"
								data-testid={`${TEST_ID}__refresh-button`}
								onClickAction={onClickRefresh}
							/>
						</Box>
					</Box>
					<Box sx={{ marginX: { xs: '0.5rem', md: '2rem' } }}>
						{props.loading ? (
							<CircularProgress
								color="secondary"
								sx={commonStyles.circularProgress}
							/>
						) : (
							<>
								<PromptCarouselComponent
									setCurrentIndex={setCurrentIndex}
									currentIndex={currentIndex}
									currentPrompts={props.currentPrompts}
									promptsEditMode={temporalPromptsEditMode}
									editable={editPromptsMode}
									setPromptValue={setPromptValue}
									disabledArrows={editPromptsMode}
								/>
								<Box sx={styles.CarouselName}>
									<Box
										flexDirection={{
											xs: 'column',
											sm: 'row',
										}}
										sx={styles.PromptsCarouselName}
									>
										<Button
											data-testid="Industry Context"
											sx={{
												...styles.PromptsName,

												borderTopLeftRadius: {
													sm: '18px',
													md: '18px',
												},
												borderBottomLeftRadius: {
													sm: '18px',
													md: '18px',
												},
											}}
											style={{
												backgroundColor:
													currentIndex === 0
														? 'rgba(255, 190, 207, 1)'
														: 'white',
											}}
											onClick={() => setCurrentIndex(0)}
											disabled={editPromptsMode}
										>
											Industry Context
										</Button>
										<Divider
											orientation="vertical"
											variant="middle"
											flexItem
											sx={styles.divider}
										/>
										<Button
											data-testid="Potential Challenges"
											sx={styles.PromptsName}
											style={{
												backgroundColor:
													currentIndex === 1
														? 'rgba(255, 190, 207, 1)'
														: 'white',
											}}
											onClick={() => setCurrentIndex(1)}
											disabled={editPromptsMode}
										>
											Potential Challenges
										</Button>
										<Divider
											orientation="vertical"
											variant="middle"
											flexItem
											sx={styles.divider}
										/>
										<Button
											data-testid="Testing Ideas"
											sx={styles.PromptsName}
											style={{
												backgroundColor:
													currentIndex === 2
														? 'rgba(255, 190, 207, 1)'
														: 'white',
											}}
											onClick={() => setCurrentIndex(2)}
											disabled={editPromptsMode}
										>
											Testing Ideas
										</Button>
										<Divider
											orientation="vertical"
											variant="middle"
											flexItem
											sx={styles.divider}
										/>
										<Button
											data-testid="Scale Opportunities"
											sx={{
												...styles.PromptsName,

												borderTopRightRadius: {
													sm: '18px',
													md: '18px',
												},
												borderBottomRightRadius: {
													sm: '18px',
													md: '18px',
												},
											}}
											style={{
												backgroundColor:
													currentIndex === 3
														? 'rgba(255, 190, 207, 1)'
														: 'white',
											}}
											onClick={() => setCurrentIndex(3)}
											disabled={editPromptsMode}
										>
											Scale Opportunities
										</Button>
									</Box>
								</Box>
							</>
						)}
					</Box>
					{editPromptsMode ? (
						<Box
							display="flex"
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							gap="2rem"
						>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={onClickSaveEdit}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEdit}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Box>
					) : (
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'end'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT"
								data-testid={`${TEST_ID}__edit-button`}
								onClickAction={onClickEdit}
							/>
						</Box>
					)}

					<Typography>
						{brainstormText.global.ideasDropdown}
					</Typography>
					<Box borderBottom={2} borderColor="rgba(255, 232, 238, 1)">
						<Button
							fullWidth
							color="inherit"
							data-testid={`${TEST_ID}-show-ai-example-button`}
							onClick={() =>
								setShowAIexampleIdea(!showAIexampleIdea)
							}
						>
							<Box
								display={'flex'}
								justifyContent="space-between"
								width="100%"
							>
								<Typography
									color="black"
									sx={{ opacity: 0.87 }}
									fontSize={{
										xs: '1.5rem',
										sm: '1.8rem',
									}}
								>
									View AI-Generated Example Ideas
								</Typography>
								<img
									alt="arrow"
									data-testid={`${TEST_ID}-arrow`}
									src={
										(showAIexampleIdea
											? arrowUp
											: arrowDown) as unknown as string
									}
								/>
							</Box>
						</Button>
						{showAIexampleIdea && (
							<Typography
								padding="1rem"
								bgcolor="rgba(255, 232, 238, 1)"
								whiteSpace="pre-wrap"
							>
								{props.currentResponse}
							</Typography>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section2
