import { memo } from 'react'

import {
	CaliforniaPrivacyNotice,
	CrossBorderDisclosures,
	DisclosureOfInfo,
	EEADisclosures,
	JapanDisclosures,
	MexicoDisclosures,
} from './Disclosures'

export const CaliforniaPrivacyNoticeCopy = memo(CaliforniaPrivacyNotice)
export const CrossBorderDisclosuresCopy = memo(CrossBorderDisclosures)
export const DisclosureOfInfoCopy = memo(DisclosureOfInfo)
export const EEADisclosuresCopy = memo(EEADisclosures)
export const JapanDisclosuresCopy = memo(JapanDisclosures)
export const MexicoDisclosuresCopy = memo(MexicoDisclosures)
