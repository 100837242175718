import React from 'react'

import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import { BaseTextField } from 'components/Form'

import { typography } from 'theme/typography'

import { TopicType } from 'enums/TopicTypeEnum'

import TopicChips from '../TopicChips'
import { ModalInputText } from '../heroData'
import { setChipColor, styles } from './EditModal.styles'

export const TEST_ID = 'hero-edit-modal'

interface IProps {
	closeModal: () => void
	companyProblem: string
	createPrompt: () => void
	editable: boolean
	gameTypeValue?: number
	innovationCompany: string
	innovationTopic?: TopicType
	isLoading: boolean
	modalText: ModalInputText
	open: boolean
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
	setInnovationTopic?: (val: TopicType) => void
}

const EditModal = (props: IProps) => {
	const {
		innovationCompany,
		innovationTopic,
		companyProblem,
		gameTypeValue,
		setCompanyProblem,
		setInnovationCompany,
		setInnovationTopic,
		closeModal,
		createPrompt,
		editable,
	} = props

	const canSubmit = () => {
		if (!innovationCompany) return false
		// Only check that the innovationTopic is falsy if it isn't undefined (it was passed as a prop)
		if (innovationTopic !== undefined && !innovationTopic) return false
		if (!companyProblem) return false
		return true
	}
	const resetAllField = () => {
		setInnovationCompany('')
		if (setInnovationTopic) {
			setInnovationTopic('')
		}
		setCompanyProblem('')
	}
	const updatePrompts = () => {
		setInnovationCompany(innovationCompany)
		// Only set these if they aren't undefined (they were passed as props from the parent)
		if (setInnovationTopic !== undefined && innovationTopic !== undefined) {
			setInnovationTopic(innovationTopic)
		}
		setCompanyProblem(companyProblem)

		createPrompt()
		closeModal()
	}

	const { chipsQuestion, inputQuestion, inputPlaceholder } = props.modalText

	return (
		<Modal
			open={props.open}
			sx={{ borderRadius: '0.5rem', borderColor: 'transparent' }}
			onClose={props.closeModal}
		>
			<Box
				display={'flex'}
				flexDirection={'column'}
				rowGap={'3rem'}
				flexGrow="1"
				sx={styles.linkModal}
			>
				<Typography>
					What company are you brainstorming about today?{' '}
					<span style={{ color: '#D32F2F' }}>*</span>
				</Typography>
				<BaseTextField
					InputLabelProps={{ style: styles.inputLabel }}
					variant="outlined"
					data-testid={`${TEST_ID}__company-name-input`}
					label="Enter company name here."
					value={innovationCompany}
					onChangeValue={(val) => setInnovationCompany(val)}
					required
					sx={styles.textField}
				/>
				{innovationTopic && (
					<>
						<Typography>
							{chipsQuestion}
							<span style={{ color: '#D32F2F' }}>*</span>
						</Typography>
						{innovationTopic && setInnovationTopic && (
							<TopicChips
								innovationTopic={innovationTopic}
								setInnovationTopic={setInnovationTopic}
								editable={editable}
								color={setChipColor(gameTypeValue)}
							/>
						)}
					</>
				)}
				<Typography>
					{inputQuestion}{' '}
					<Box component="span" sx={typography.asterisk}>
						*
					</Box>
				</Typography>
				<BaseTextField
					InputLabelProps={{
						style: styles.inputLabel,
					}}
					variant="outlined"
					data-testid={`${TEST_ID}__company-problem-input`}
					label={inputPlaceholder}
					onChangeValue={(val) => setCompanyProblem(val)}
					value={companyProblem}
					required
					sx={styles.textField}
				/>
				<Box
					display="flex"
					sx={{
						flexDirection: {
							xs: 'column-reverse',
							sm: 'row',
						},
					}}
					justifyContent="flex-end"
					gap="2rem"
				>
					<Button
						variant="text"
						color="secondary"
						data-testid={`${TEST_ID}__reset-all-button`}
						onClick={resetAllField}
					>
						RESET ALL FIELDS
					</Button>
					<Button
						color="secondary"
						variant="contained"
						data-testid={`${TEST_ID}__create-prompt-button`}
						disabled={!canSubmit() || props.isLoading}
						onClick={updatePrompts}
					>
						Start Ideating
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}
export default EditModal
