import React, { Dispatch, SetStateAction, useRef } from 'react'

import { Button, CircularProgress, Grid, Typography } from '@mui/material'

import { RichTextField } from 'components/Form'
import IdeaFlameMotivator, {
	useRichTextToIdeas,
} from 'components/IdeaFlameMotivator/IdeaFlameMotivator'

import { typography } from 'theme/typography'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section3.styles'

export const TEST_ID = 'musical-chairs-section3'

export type Props = {
	authorIdeation: string
	cautionText?: string
	gameId?: string
	loading: boolean
	setAuthorIdeation: (val: string) => void
	setTermsAgreed: Dispatch<SetStateAction<boolean>>
	submitIdeas: () => void
	submitIdeasDisabled: () => boolean
	termsAgreed: boolean
	viewSubmittedIdeas: () => void
}

const Section3 = (props: Props) => {
	const richTextFieldRef = useRef(null)
	const richTextToIdeas = useRichTextToIdeas()

	function handleCheckbox() {
		props.setTermsAgreed(!props.termsAgreed)
	}

	return (
		<Grid container>
			<Grid item sm={12}>
				<Typography sx={typography.overline}>3. IDEATION</Typography>
			</Grid>
			<Grid container>
				<Grid item sx={styles.sideBar} />
				<Grid
					item
					xs
					container
					direction="column"
					sx={styles.contentContainer}
				>
					<Grid item>
						<Typography
							data-testid={`${TEST_ID}__prompt-text`}
							sx={styles.typography.question}
						>
							How can you borrow inspiration from this prompt and
							apply it to our innovation topic?
						</Typography>
					</Grid>

					<Grid
						item
						sx={styles.ideationContainer}
						data-testid={`${TEST_ID}__ideation`}
					>
						<RichTextField
							ref={richTextFieldRef}
							onChange={props.setAuthorIdeation}
							value={props.authorIdeation}
							placeholderText="Brainstorm your ideas here!"
							sx={styles.richTextField}
							iconColor="#45657B"
						/>
					</Grid>
					<Grid item>
						<IdeaFlameMotivator
							ideaCount={richTextToIdeas(props.authorIdeation)}
							variant="MUSICAL_CHAIRS"
						/>
					</Grid>

					{props.gameId && (
						<Grid item>
							<input
								type="checkbox"
								checked={props.termsAgreed}
								onChange={handleCheckbox}
								data-testid={`${TEST_ID}__term_agreement_checkbox`}
							/>
							<Typography
								data-testid={`${TEST_ID}__terms-text`}
								sx={styles.typography.terms}
							>
								I understand my submitted ideas are only visible
								to me and the game creator.
							</Typography>
						</Grid>
					)}

					<Grid item>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__submit-idea-button`}
							onClick={props.submitIdeas}
							disabled={
								props.submitIdeasDisabled() || props.loading
							}
						>
							{props.loading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>SUBMIT IDEAS</Typography>
							)}
						</Button>
						<Button
							disabled={props.loading}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__view-submit-ideas-button`}
							onClick={props.viewSubmittedIdeas}
						>
							{props.loading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>
									VIEW ALL SUBMITTED IDEAS
								</Typography>
							)}
						</Button>
					</Grid>

					<Grid item>
						<Typography
							data-testid={`${TEST_ID}__caution-text`}
							sx={styles.typography.caution}
						>
							{props.cautionText}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Section3
