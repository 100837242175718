import React, { forwardRef } from 'react'

import LockRounded from '@mui/icons-material/LockRounded'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'

import { useInputBehavior } from 'hooks/useInputBehavior'

export const TEST_ID = 'default-test-id-text-field'

const readOnlyInputProps = {
	readOnly: true,
	endAdornment: (
		<InputAdornment position="end">
			<LockRounded />
		</InputAdornment>
	),
}

export type Props = {
	'data-testid'?: string
	multiline?: boolean
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onChangeValue?: (val: string) => void
	readOnly?: boolean
} & TextFieldProps

const BaseTextField = forwardRef<HTMLInputElement, Props>(
	(
		{
			multiline,
			readOnly,
			'data-testid': dataTestId,
			onBlur,
			onChange,
			onChangeValue,
			...props
		},
		ref
	) => {
		const { handleChange, handleBlur } = useInputBehavior(
			onChangeValue || (() => {})
		)
		return (
			<TextField
				inputRef={ref}
				fullWidth
				inputProps={{ 'data-testid': dataTestId || TEST_ID }}
				multiline={multiline}
				size="small"
				variant="filled"
				InputProps={{
					...((readOnly && readOnlyInputProps) ||
						props.InputProps ||
						{}),
				}}
				onBlur={(e) => {
					handleBlur(e.target.value)
					onBlur && onBlur(e)
				}}
				onChange={(e) => {
					handleChange(e.target.value)
					onChange && onChange(e)
				}}
				onKeyDown={(e) =>
					e.key === 'Enter' &&
					!(e.target instanceof HTMLTextAreaElement) &&
					e.preventDefault()
				}
				{...props}
			/>
		)
	}
)

BaseTextField.displayName = 'BaseTextField'

export default BaseTextField
