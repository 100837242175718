import axios, { AxiosResponse } from 'axios'

import { getSignedRequest } from '../utils/authUtils'

export const updateUser = async (data: {
	firstName: string
	lastName: string
}): Promise<AxiosResponse> => {
	const signed = await getSignedRequest('PUT', `user`, JSON.stringify(data))

	if (signed) {
		return await axios({
			...signed,
			url: (process.env.REACT_APP_AWS_API_URL as string) + `user`,
			data,
			method: 'PUT',
		})
	}
	throw Error('Unable to get signed request')
}

export const deleteUser = async (): Promise<AxiosResponse> => {
	const signed = await getSignedRequest('DELETE', `user`)

	if (signed) {
		return await axios({
			...signed,
			url: (process.env.REACT_APP_AWS_API_URL as string) + `user`,
			method: 'DELETE',
		})
	}
	throw Error('Unable to get signed request')
}

export const getUser = async (): Promise<AxiosResponse> => {
	const signed = await getSignedRequest('GET', `user`)

	if (signed) {
		return await axios({
			...signed,
			url: (process.env.REACT_APP_AWS_API_URL as string) + `user`,
			method: 'GET',
		})
	}
	throw Error('Unable to get signed request')
}
