import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { TopicType } from 'enums/TopicTypeEnum'

import { TeeterTotterPrompt } from './ideaModels'

type Conversation = {
	content: string
	role: string
}

export function TeeterTotterModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [centerCategory, setCenterCategory] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const gameTypeId = GAME_TYPE.TEETER_TOTTER.value
	const [innovationTopic, setInnovationTopic] = useState<TopicType>('')
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [leftExtreme, setLeftExtreme] = useState<string>('')
	const [rightExtreme, setRightExtreme] = useState<string>('')
	const [termsAgreed, setTermsAgreed] = useState(false)
	const [previousPrompts, setPreviousPrompts] = useState<number[]>([])
	const [currentPrompts, setCurrentPrompts] = useState<TeeterTotterPrompt>({
		leftConstraint: '',
		promptId: '',
		rightConstraint: '',
		topic: '',
	})
	const [responseConversation, setResponseConversation] = useState<
		Conversation[]
	>([])
	const [currentResponses, setCurrentResponses] = useState<string>('')
	const generatePromptsDisabled = () => {
		return (
			!innovationTopic.length ||
			!innovationCompany.length ||
			!companyProblem.length
		)
	}

	const setPromptResponse = (prompts: TeeterTotterPrompt) => {
		setCurrentPrompts(prompts)
	}

	const getRandomPromptsQueryObj = () => {
		return { previousPrompts }
	}

	const submitIdeasDisabled = () => {
		if (
			authorIdeation.length === 0 ||
			authorIdeation === '<p></p>' ||
			(gameId && !termsAgreed)
		)
			return true
		else return false
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}

	const responseToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
			prompt: currentPrompts,
			conversation: responseConversation,
		}
	}

	const resetSoft = () => {
		setResponseConversation([])
		setAuthorIdeation('')
		setCurrentResponses('')
	}

	const reset = () => {
		resetSoft()
	}

	return {
		authorIdeation,
		centerCategory,
		companyProblem,
		gameToJSON,
		generatePromptsDisabled,
		termsAgreed,
		setTermsAgreed,
		submitIdeasDisabled,
		innovationCompany,
		innovationTopic,
		leftExtreme,
		reset,
		resetSoft,
		rightExtreme,
		setAuthorIdeation,
		setCenterCategory,
		setCompanyProblem,
		setGameId,
		setInnovationCompany,
		setInnovationTopic,
		setLeftExtreme,
		setRightExtreme,
		setPromptResponse,
		getRandomPromptsQueryObj,
		currentPrompts,
		setCurrentPrompts,
		setPreviousPrompts,
		setResponseConversation,
		responseConversation,
		setCurrentResponses,
		currentResponses,
		responseToJSON,
		previousPrompts,
	}
}
