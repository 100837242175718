import { useEffect, useState } from 'react'

import Close from '@mui/icons-material/Close'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Portal,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'

import { brainstormText } from 'assets/brainstormText'

import { styles } from './IdeationRecommendationModal.styles'

interface IdeationRecommendationModalProps {
	onBack: () => void
	onClose: () => void
	refreshClicksSinceIdeation: number
}

const text = brainstormText.global.ideationRecommendationModal

export const TEST_ID = 'ideation-recommendation'

export const IdeationRecommendationModal = (
	props: IdeationRecommendationModalProps
) => {
	const { refreshClicksSinceIdeation, onBack, onClose } = props
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (refreshClicksSinceIdeation > 2) {
			setOpen(true)
		} else {
			setOpen(false)
		}
	}, [refreshClicksSinceIdeation])

	return (
		<Portal container={() => document.getElementsByTagName('main')[0]!}>
			<Dialog onClose={onClose} open={open} sx={styles.dialog}>
				<DialogContent sx={styles.dialogContent}>
					<Stack>
						{isMobile && (
							<Stack
								direction="row"
								style={styles.closeContainer}
							>
								<IconButton onClick={onClose}>
									<Close />
								</IconButton>
							</Stack>
						)}
						<Typography
							variant="h6"
							sx={styles.title}
							data-testid={`${TEST_ID}__title`}
						>
							{text.title}
						</Typography>
					</Stack>

					<Stack spacing={1} sx={styles.textContainer}>
						<Typography
							variant="body1"
							sx={styles.body1}
							data-testid={`${TEST_ID}__body1`}
						>
							{text.bodyText1}
						</Typography>
						<Typography
							variant="body1"
							sx={styles.body1}
							data-testid={`${TEST_ID}__body2`}
						>
							{text.bodyText2}
						</Typography>
					</Stack>
				</DialogContent>
				<DialogActions sx={styles.dialogActions}>
					<Stack spacing={2} width="100%">
						<Button
							color="secondary"
							variant="contained"
							onClick={onClose}
							data-testid={`${TEST_ID}__primary-button`}
						>
							{text.primaryButton}
						</Button>
						<Button
							color="secondary"
							variant="outlined"
							onClick={onBack}
							data-testid={`${TEST_ID}__secondary-button`}
						>
							{text.secondaryButton}
						</Button>
					</Stack>
				</DialogActions>
			</Dialog>
		</Portal>
	)
}
