export const styles = {
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		marginRight: '2rem',
		background: 'linear-gradient(180deg, #12BBF182 51%, #12BBF182 100%)',
	},
	responseContainer: {
		backgroundColor: 'rgba(231, 248, 255, 1)',
		padding: '1rem',
	},
	responseText: {
		fontSize: 16,
		color: 'black',
		opacity: 0.87,
	},
	listItem: {
		margin: '10px 0 10px 0',
	},
	mainContainer: {
		flexDirection: 'column',
		gap: '2rem',
		flexGrow: '1',
		paddingY: { xs: '0', sm: '1rem' },
	},
	headerContainer: {
		flexDirection: { xs: 'column', sm: 'row' },
		justifyContent: 'space-between',
		alignItems: { xs: 'flex-start', sm: 'center' },
		gap: '1rem',
	},
	radioButtonSectionContainer: {
		display: 'flex',
		alignItems: { xs: 'flex-start', sm: 'center' },
		justifyContent: { xs: 'flex-start', sm: 'space-between' },
		flexDirection: { xs: 'column', sm: 'row' },
		gap: '1rem',
	},
	radioButtonSelection: {
		color: '#E7F8FF', // Default color when not checked
		'&.Mui-checked': {
			color: '#45657b', // Color when the radio button is selected
		},
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '1rem',
	},
	editButtonsContainer: {
		flexDirection: { xs: 'column', sm: 'row' },
		gap: '2rem',
	},
	editPromptContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
	},
	textFieldContainer: {
		'.MuiInputBase-root': {
			height: '100%',
		},
	},
	cardContainer: {
		padding: '6px 8px 6px 0',
		alignItems: 'center',
		alignContent: 'center',
		gap: '8px',
		flexWrap: 'wrap',
	},
	numberedCard: {
		alignItems: 'center',
		gap: '12px',
		flexShrink: 0,
		'@media (min-width: 835px)': {
			width: '32%',
		},
	},
	cardHeader: {
		height: '76px',
		padding: '8px',
		alignItems: 'center',
		flexShrink: 0,
		alignSelf: 'stretch',
		borderRadius: '4px',
		border: '1px solid var(--light-other-outlined-border-23-p, #0000003B)',
		background: 'var(--Light-Background-Paper, #FFF)',
	},
	stepNumber: {
		width: '32px',
		height: '32px',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '10rem',
		border: '1px solid var(--light-other-outlined-border-23-p, #0000003B)',
		background: 'var(--white-white, #FFF)',
	},
	stepNumberFont: {
		color: 'var(--Light-Text-Primary, #000000DE)',
		textAlign: 'center',
		fontFamily: 'sans-serif',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: '28px',
	},
	numberedCardText: {
		padding: '0 8px',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
		flexGrow: 1,
		alignContent: 'center',
		width: 'min-content',
	},
	numberedCardFont: {
		color: 'var(--Light-Text-Primary, #000000DE)',
		fontFamily: 'Roboto',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '17.16px',
		letterSpacing: '0.17px',
	},
	dividerContainerVisible: {
		visibility: 'visible',
	},
	dividerContainerHidden: {
		visibility: 'hidden',
	},
	boxDivider: {
		border: '1px dotted #0000003B',
		width: '19px',
	},
	fullHeight: {
		height: '100%',
	},
	contentContainer: {
		rowGap: '3rem',
	},
}
