import React, { useState } from 'react'

import RefreshIcon from '@mui/icons-material/Refresh'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useToastContext } from 'contexts/ToastProvider'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import IconImage from 'components/Icon/IconImage'
import InfoPopper from 'components/InfoPopper/InfoPopper'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/Pencil.svg'
import arrowDown from 'assets/images/arrowDown.svg'
import arrowUp from 'assets/images/arrowUp.svg'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section2.styles'

export const TEST_ID = 'simon-says-section2'

type BulletItem = {
	details: string
	title: string
}
type PopperContent = {
	bulletItems: BulletItem[]
	header: string
	introText: string
}
export type Props = {
	createPrompt: () => void
	currentResponses: string[]
	customer: string
	generateAiImage: () => Promise<void>
	generateImageDisabled: () => boolean
	imageAvailable: boolean
	imageData: string | null
	isAiImageLoading: boolean
	isCurrentResponseLoading: boolean
	isRandomLoading: boolean
	loading: boolean
	manualChangeCustomerPrompts: (newCustomer: string) => void
	popperContent: PopperContent
	randomElement: string
	refreshRandom: () => void
	resetImage: () => void
}

const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [showAIexampleIdea, setShowAIexampleIdea] = useState(false)
	const [editPromptsMode, setEditPromptsMode] = useState(false)
	const [
		temporalCustomerEditPromptsMode,
		setTemporalCustomerEditPromptsMode,
	] = useState<string>('')

	const onClickRefresh = () => {
		props.createPrompt()
	}
	const onClickImageRefresh = () => {
		if (
			props.generateImageDisabled() ||
			props.loading ||
			props.isAiImageLoading
		)
			return
		props.generateAiImage()
	}
	const onClickClearImage = () => {
		if (
			props.generateImageDisabled() ||
			props.loading ||
			props.isAiImageLoading
		)
			return
		props.resetImage()
	}

	const onClickEdit = () => {
		setTemporalCustomerEditPromptsMode(props.customer)
		setEditPromptsMode(true)
	}

	const onClickCancelEdit = () => {
		setEditPromptsMode(false)
	}

	const onClickSaveEdit = () => {
		props.manualChangeCustomerPrompts(temporalCustomerEditPromptsMode)
		setShowAIexampleIdea(false)
		setEditPromptsMode(false)
		if (showAlert) {
			showAlert({
				objectType: 'editPrompt',
				severity: ToastSeverity.SUCCESS,
			})
		}
	}
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const handleClickInfoIcon = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
					paddingY={{ xs: '0', sm: '1rem' }}
				>
					<Box
						display={'flex'}
						flexDirection={{
							xs: 'column',
							sm: 'column',
						}}
						justifyContent={'space-between'}
						gap={'1rem'}
					>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							alignItems={{
								xs: 'flex-start',
								sm: 'center',
							}}
							gap={'1rem'}
							padding={0}
						>
							<Typography
								data-testid={`${TEST_ID}__intro-text`}
								color="black"
								sx={{ opacity: 0.87 }}
								fontSize={{
									xs: 16,
									sm: 20,
								}}
								gap={'0.5rem'}
								maxWidth={{
									sm: '488px',
									md: '600px',
									lg: '940px',
								}}
							>
								How could you solve the innovation topic
								specifically for the following customer, even if
								they don’t relate to your normal business
								operations?
								<InfoPopper
									handleClick={handleClickInfoIcon}
									anchorEl={anchorEl}
									popperContent={props.popperContent}
								/>
							</Typography>
							<Box
								display={'inline-flex'}
								flexDirection={'row'}
								justifyContent={'flex-end'}
								alignItems={'center'}
								gap={'0.5rem'}
								padding={'6px 8px'}
							>
								<ButtonWithIconComponent
									sx={{ padding: 0 }}
									disabled={props.loading}
									Icon={<RefreshIcon fontSize="small" />}
									text="REFRESH"
									data-testid={`${TEST_ID}__refresh-button`}
									onClickAction={onClickRefresh}
									iconWidth={'13px'}
								/>
							</Box>
						</Box>
						<Box
							display={'flex'}
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							justifyContent={'space-between'}
							gap={'3rem'}
							bgcolor={'rgba(237, 231, 246, 0.8)'}
							padding={'2rem'}
						>
							<Box
								display={'flex'}
								flexDirection={'column'}
								gap={'2rem'}
								flex={'1'}
							>
								<Box
									display={'flex'}
									flexDirection={'column'}
									gap={'1rem'}
								>
									<Typography fontWeight={400} fontSize={12}>
										CUSTOMER TYPE
									</Typography>
									{props.loading ? (
										<CircularProgress
											color="secondary"
											sx={commonStyles.circularProgress}
										/>
									) : editPromptsMode ? (
										<TextField
											data-testid={`${TEST_ID}__customer-editable-field`}
											multiline
											fullWidth
											sx={styles.editPromptsMode}
											value={
												temporalCustomerEditPromptsMode
											}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>
											) => {
												setTemporalCustomerEditPromptsMode(
													event.target.value
												)
											}}
										/>
									) : (
										<Typography
											data-testid={`${TEST_ID}__customer`}
											sx={styles.promptsText}
											borderBottom={
												'1px solid rgba(0, 0, 0, 0.42)'
											}
											paddingBottom={'0.5rem'}
										>
											{props.customer}
										</Typography>
									)}
								</Box>
								<Box
									display={'flex'}
									flexDirection={'column'}
									gap={'1rem'}
								>
									<Box
										display={'flex'}
										flexDirection={'row'}
										justifyContent={'start'}
										alignItems={'center'}
										gap={'1rem'}
									>
										<Typography
											fontWeight={400}
											fontSize={12}
											paddingTop={'0.156rem'}
										>
											CUSTOMER CONNECTION POINT
										</Typography>
									</Box>
									<Box display={'flex'} gap={'1rem'}>
										<Box
											color={'rgba(0, 0, 0, 0.87)'}
											fontWeight={400}
											fontSize={16}
										>
											How could{' '}
											{props.loading ||
											props.isRandomLoading ? (
												<CircularProgress
													color="secondary"
													size={26}
												/>
											) : (
												<Typography
													data-testid={`${TEST_ID}__random-element`}
													color={
														'rgba(0, 0, 0, 0.87)'
													}
													fontWeight={500}
													fontSize={{
														xs: 16,
														sm: 20,
													}}
													display={'inline'}
													borderBottom={
														'1px solid rgba(0, 0, 0, 0.42)'
													}
													marginX={'0.5rem'}
												>
													{props.randomElement}
													<ButtonWithIconComponent
														sx={{
															display: 'inline',
															padding:
																'0 0 0 1rem',
														}}
														disabled={props.loading}
														Icon={
															<RefreshIcon fontSize="small" />
														}
														text=""
														data-testid={`${TEST_ID}__challenge-refresh-button`}
														onClickAction={
															props.refreshRandom
														}
														iconWidth={'14px'}
													/>
												</Typography>
											)}{' '}
											influence any new ideas or thoughts?
										</Box>
									</Box>
								</Box>
								<Box
									minWidth={{
										xs: '100%',
										sm: '280px',
										md: '280px',
										lg: '300px',
									}}
								>
									{props.imageAvailable ? (
										<Box
											alt="AI generated image"
											component="img"
											src={`data:image/png;base64,${props.imageData}`}
											maxHeight={'300px'}
											data-testid={`${TEST_ID}__generated-image`}
											sx={{ marginTop: 2 }}
										/>
									) : (
										<Box
											onClick={onClickImageRefresh}
											data-testid={`${TEST_ID}__image-generate-button`}
											sx={{
												cursor: 'pointer',
												height: '20px',
											}}
										>
											{props.isAiImageLoading ? (
												<CircularProgress
													sx={
														commonStyles.circularProgress
													}
												/>
											) : (
												<Box
													display="flex"
													alignItems="center"
													sx={{
														cursor: 'pointer',
													}}
												>
													<IconImage size={20} />
													<Typography
														sx={{
															marginTop: 0.3,
															marginLeft: 1,
															fontSize: '13px',
															fontWeight: '500 ',
														}}
													>
														GENERATE IMAGE
													</Typography>
												</Box>
											)}
										</Box>
									)}
									{props.isAiImageLoading ? (
										<Box minHeight={'2rem'}></Box>
									) : (
										<Box
											minHeight={'2rem'}
											display={'flex'}
											flexDirection={'row'}
											justifyContent={
												props.imageAvailable
													? 'space-between'
													: 'flex-end'
											}
											alignItems={'center'}
											padding={0}
											gap={'0.5rem'}
										>
											{props.imageAvailable && (
												<Box
													display="flex"
													justifyContent="space-between"
													alignItems="center"
												>
													<ButtonWithIconComponent
														sx={{
															paddingTop: 0.5,
														}}
														disabled={
															props.generateImageDisabled() ||
															props.loading ||
															props.isAiImageLoading ||
															!props.imageAvailable
														}
														Icon={
															<VisibilityOff fontSize="small" />
														}
														text="CLEAR IMAGE"
														data-testid={`${TEST_ID}__clear-image-button`}
														onClickAction={
															onClickClearImage
														}
														iconWidth={'13px'}
													/>
													<ButtonWithIconComponent
														sx={{
															paddingTop: 0.5,
															paddingLeft: 1,
														}}
														disabled={
															props.generateImageDisabled() ||
															props.loading ||
															props.isAiImageLoading
														}
														Icon={
															<RefreshIcon fontSize="small" />
														}
														text="REFRESH IMAGE"
														data-testid={`${TEST_ID}__image-refresh-button`}
														onClickAction={
															onClickImageRefresh
														}
														iconWidth={'13px'}
													/>
												</Box>
											)}
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
					{editPromptsMode ? (
						<Box
							display="flex"
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							gap="2rem"
						>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={onClickSaveEdit}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEdit}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Box>
					) : (
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'end'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT"
								data-testid={`${TEST_ID}__edit-button`}
								onClickAction={onClickEdit}
							/>
						</Box>
					)}
					{!!props.currentResponses && (
						<>
							<Typography
								color="black"
								sx={{ opacity: 0.87 }}
								fontSize={{
									xs: 16,
									sm: 20,
								}}
							>
								{brainstormText.global.ideasDropdown}
							</Typography>
							<Box>
								<Box borderBottom={2} borderColor={'#D6D4FF'}>
									<Button
										fullWidth
										color="inherit"
										data-testid={`${TEST_ID}-show-ai-example-button`}
										onClick={() =>
											setShowAIexampleIdea(
												!showAIexampleIdea
											)
										}
									>
										<Box
											display={'flex'}
											justifyContent="space-between"
											width="100%"
										>
											<Typography
												color="black"
												sx={{ opacity: 0.87 }}
												fontSize={12}
												lineHeight={'266%'}
											>
												View AI-Generated Example Ideas
											</Typography>
											<img
												alt="arrow"
												data-testid={`${TEST_ID}-arrow`}
												src={
													(showAIexampleIdea
														? arrowUp
														: arrowDown) as unknown as string
												}
											/>
										</Box>
									</Button>
								</Box>
								{showAIexampleIdea && (
									<Box
										bgcolor={'rgba(237, 231, 246, 0.8)'}
										padding={'1rem'}
									>
										{props.isCurrentResponseLoading && (
											<CircularProgress
												color="secondary"
												sx={
													commonStyles.circularProgress
												}
											/>
										)}
										<ol data-testid="gen-ai-response-list">
											{props.currentResponses.map(
												(el: string, i: number) => (
													<Typography
														fontSize={16}
														color="black"
														component="li"
														key={`ai-solution-${i}`}
														sx={{ opacity: 0.87 }}
														variant="body1"
														style={{
															margin: '10px 0 10px 0',
														}}
													>
														{el}
													</Typography>
												)
											)}
										</ol>
									</Box>
								)}
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	)
}
export default Section2
