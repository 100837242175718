import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'
import {
	Box,
	Chip,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography,
	useTheme,
} from '@mui/material'
import { debounce, isEmpty, omitBy } from 'lodash'

import { Idea, SubmittedIdeaCSV, dropdownItem } from 'models/ideaModels'

import { getInitialIdeas } from 'services/ideas.service'

import { ButtonWithIcon } from 'components/ButtonWithIcon/ButtonWithIcon'
import { FormDropdown } from 'components/Form'

import useApi from 'hooks/useApi'

import ExportButton from '../ExportButton/ExportButton'
import { styles } from './FilterSection.styles'

export const TEST_ID = 'gen-ai-question-and-filter-section'

type Props = {
	displayCount?: number
	ideas?: Idea[]
	selectedIdeas?: Idea[]
	totalCount?: number
}

enum filterType {
	AUTHOR_ID,
	GAME_ID,
}

export default function FilterSection({
	ideas,
	displayCount,
	selectedIdeas,
	totalCount,
}: Props) {
	const [filters, setFilters] = useState<
		Record<
			string,
			{
				label: string
				options: dropdownItem[]
			}
		>
	>({})
	const [viewSearch, setViewSearch] = useState(false)

	const { data, requestApi, loading } = useApi(getInitialIdeas)

	const [searchParams, setSearchParams] = useSearchParams()

	const theme = useTheme()

	type FormSchema = {
		authorId?: (string | undefined)[]
		authorIdeation?: string
		company?: (string | undefined)[]
		gameId?: (string | undefined)[]
		genAIQuestion?: string
	}

	const { control, setValue, watch } = useForm<FormSchema>({
		defaultValues: {
			company: searchParams.getAll('company') ?? [],
			gameId:
				searchParams
					.getAll('gameId')
					.flatMap((item) => item.split(',')) ?? [],
			authorId:
				searchParams
					.getAll('authorId')
					.flatMap((item) => item.split(',')) ?? [],
			genAIQuestion: searchParams.get('genAIQuestion') ?? '',
			authorIdeation: searchParams.get('authorIdeation') ?? '',
		},
		mode: 'onChange',
		reValidateMode: 'onChange',
	})

	const authorIds = watch('authorId')
	const gameIds = watch('gameId')

	const itemsMemo = useMemo(() => {
		return [
			...(authorIds || [])
				.filter((item) => item !== undefined && item !== null)
				.map((item) => ({
					value: item,
					type: filterType.AUTHOR_ID,
				})),
			...(gameIds || [])
				.filter((item) => item !== undefined && item !== null)
				.map((item) => ({
					value: item,
					type: filterType.GAME_ID,
				})),
		]
	}, [authorIds, gameIds])

	const optionMap = useMemo(() => {
		const filterOptions = Object.entries(filters).flatMap(
			([, val]) => val.options
		)

		return new Map(
			filterOptions.map((item) => {
				if (typeof item === 'string') {
					return [item, item]
				}

				return [item.value, item.label]
			})
		)
	}, [filters])

	useEffect(() => {
		const subscription = watch(
			debounce((data) => {
				setSearchParams(
					omitBy(
						{
							...data,
							authorId: data.authorId.join(','),
							gameId: data.gameId.join(','),
						},
						isEmpty
					),
					{ replace: true }
				)
			}, 500)
		)
		return () => subscription.unsubscribe()
	}, [watch, setSearchParams])

	useEffect(() => {
		requestApi()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setFilters({
			authorId: {
				label: 'Author',
				options: data?.authors ?? [],
			},
			gameId: {
				label: 'Custom Game',
				options: data?.gameIds ?? [],
			},
		})
	}, [data])

	// TODO: Complete search filter implementation for submitted ideas
	// https://slalom.atlassian.net/browse/BRAIN-1115
	const searchInput = debounce((search) => {
		setValue('authorIdeation', search)
	}, 500)

	return (
		<Box component="form" sx={styles.gridContainer}>
			<Grid container sx={styles.filterGrid} maxWidth={'xl'}>
				{viewSearch && (
					<Grid container item>
						<Box
							data-testid={`${TEST_ID}-search-input-container`}
							sx={styles.searchBox}
						>
							<OutlinedInput
								name="authorIdeation"
								defaultValue={searchParams.get(
									'authorIdeation'
								)}
								startAdornment={
									<InputAdornment position="start">
										<IconButton>
											<Search />
										</IconButton>
									</InputAdornment>
								}
								inputProps={{
									'data-testid': `${TEST_ID}-search-input`,
								}}
								placeholder="Search..."
								onChange={({ target }) =>
									searchInput(target.value)
								}
								sx={styles.searchBarStyle}
							/>
						</Box>
					</Grid>
				)}
				<Grid container item sx={styles.filterSection}>
					<ButtonWithIcon
						onClickAction={() => setViewSearch(!viewSearch)}
						Icon={
							viewSearch ? (
								<CloseIcon sx={styles.iconButtonSvg} />
							) : (
								<Search sx={styles.iconButtonSvg} />
							)
						}
						sx={{
							...styles.iconButton,
							border: `${theme.palette.secondary.main} 1px solid`,
						}}
					/>
					{!loading &&
						Object.entries(filters).map(([name, filter], index) => {
							return (
								<Grid item key={name} xs={12} sm={5} lg={3}>
									<FormDropdown
										control={control}
										data-testid={`${TEST_ID}-dropdown-${index}`}
										name={name}
										label={filter.label}
										required={false}
										options={[...filter.options]}
										readOnly={false}
										multiple={true}
										sx={styles.dropdownFilter}
									/>
								</Grid>
							)
						})}
					{displayCount && totalCount && (
						<Grid container item sx={styles.itemCountSection}>
							<Typography
								data-testid={`${TEST_ID}-idea-count`}
								sx={styles.itemCountText}
							>
								{`${selectedIdeas?.length ? 'Selected' : 'Visible'} Ideas: ${selectedIdeas?.length || displayCount} of ${totalCount}`}
							</Typography>
						</Grid>
					)}
					<ExportButton
						data={
							(selectedIdeas?.length
								? selectedIdeas
								: ideas) as SubmittedIdeaCSV[]
						}
					/>
				</Grid>
				<Grid container item sx={styles.chipContainer}>
					{itemsMemo.map((item, index) => (
						<Chip
							key={item.value}
							label={optionMap.get(item.value!)}
							variant="outlined"
							data-testid={`${TEST_ID}-chip-${index}`}
							onDelete={() => {
								if (!authorIds || !gameIds) {
									return
								}

								const newAuthorIds = authorIds.filter(
									(val) => val !== item.value
								)
								const newgameIds = gameIds.filter(
									(val) => val !== item.value
								)

								if (item.type === filterType.AUTHOR_ID) {
									setValue('authorId', newAuthorIds)
								} else if (item.type === filterType.GAME_ID) {
									setValue('gameId', newgameIds)
								}
							}}
						/>
					))}
				</Grid>
			</Grid>
		</Box>
	)
}
