export const Cookies = () => {
	return (
		<div id="cookies" data-testid="cookies">
			<p>
				<b>Cookies</b>
			</p>
			<p>
				Brainstorm uses cookies to distinguish you from other users of
				Brainstorm. This helps us to provide you with a good experience
				when you use Brainstorm and also allows us to customize and
				improve Brainstorm. For detailed information on the cookies we
				use and the purposes for which we use them see our{' '}
				<a href="https://www.slalom.com/us/en/legal/cookie-policy">
					Cookies Policy
				</a>
				. Some web browsers include a Do-Not-Track (&quot;DNT&quot;)
				feature or setting you can activate to signal your privacy
				preference not to have data about your online browsing
				activities monitored and collected. You can make certain choices
				about cookies and similar technologies to communicate your
				choice not to be tracked online in our{' '}
				<u>privacy preference center</u>. But we do not respond to DNT
				signals.
			</p>
		</div>
	)
}
