/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios'

import {
	getInvitedUsersResponse,
	sendEmailInvite,
	sendEmailInviteResponse,
} from '../models/emailInviteModel'
import { getSignedRequest } from '../utils/authUtils'

export const sendGameInvite = async (
	data: sendEmailInvite
): Promise<sendEmailInviteResponse> => {
	const signed = await getSignedRequest(
		'POST',
		'email/emailGameInvite',
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				'email/emailGameInvite',
			data,
			method: 'POST',
		})
	} else {
		throw new Error('Unable to get signed Request')
	}
}

export const getInvitedUsers = async (
	gameId: string
): Promise<getInvitedUsersResponse> => {
	const signed = await getSignedRequest('GET', `email/invitedUser/${gameId}`)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`email/invitedUser/${gameId}`,
			method: 'GET',
		})
	} else {
		throw new Error('Unable to get signed Request')
	}
}

export const removeInvitedUser = async (
	gameId: string,
	userId: string
): Promise<AxiosResponse> => {
	const signed = await getSignedRequest(
		'DELETE',
		`email/invitedUser/${gameId}/${userId}`
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`email/invitedUser/${gameId}/${userId}`,
			method: 'DELETE',
		})
	} else {
		throw new Error('Unable to get signed request')
	}
}

export const updateInvitedUser = async (
	firstName: string,
	lastName: string,
	email: string
): Promise<AxiosResponse> => {
	try {
		const signed = await getSignedRequest(
			'PATCH',
			`email/invitedUser/${firstName}/${lastName}/${email}`
		)

		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`email/invitedUser/${firstName}/${lastName}/${email}`,
			method: 'PATCH',
		})
	} catch (error) {
		throw new Error('Error occurred in updateInvitedUser', error)
	}
}
