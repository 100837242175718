import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import { GeneratedExampleDropdown } from 'components/GeneratedExampleDropdown/GeneratedExampleDropdown'
import { generatedExampleDropdownData } from 'components/GeneratedExampleDropdown/generatedExampleDropdownData'
import InfoPopper from 'components/InfoPopper/InfoPopper'
import PromptBoxV2 from 'components/PromptBoxV2'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import { brainstormText } from 'assets/brainstormText'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { styles as BaseStyles } from '../../LeapFrog.styles'
import { styles as Section1Styles } from '../Section1/Section1.styles'

export const TEST_ID = 'leap-frog-section2'

type Response = {
	ideas: string[]
	title: string
}
type BulletItem = {
	details: string
	title: string
}
type PopperContent = {
	bulletItems: BulletItem[]
	header: string
	introText: string
}

export type Props = {
	currentPrompts: string[]
	currentResponses: Response[]
	generatePromptsDisabled: () => boolean
	handleAiResponseClick: () => void
	handleRandomPrompts: () => void
	isAiLoading: boolean
	isLoading: boolean
	popperContent: PopperContent
}

const Section2 = ({
	isLoading,
	isAiLoading,
	currentPrompts,
	currentResponses,
	generatePromptsDisabled,
	handleRandomPrompts,
	popperContent,
}: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const gameId = GAME_TYPE.LEAP_FROG.value as number

	const gameHeroData = generatedExampleDropdownData[gameId]

	const handleClickInfoIcon = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	const listStyle = {
		background: gameHeroData.bg,
		padding: '2em',
		margin: 0,
	}

	const buildResponseItem = (solution: Response[]) => {
		return (
			<ul data-testid="gen-ai-response-custom-list" style={listStyle}>
				{solution.map((el, i) => (
					<React.Fragment key={i}>
						<Typography
							component="li"
							key={`ai-solution-${i}`}
							sx={BaseStyles.solutionText}
							variant="body1"
						>
							{el.title}
						</Typography>
						<ol data-testid="gen-ai-sub-response-list">
							{el.ideas.map((subel, x) => (
								<Typography
									fontSize={16}
									color="black"
									component="li"
									key={`ai-sub-solution-${x}`}
									sx={BaseStyles.solutionText}
									style={{
										margin: '10px 0 10px 0',
									}}
									variant="body1"
								>
									{subel}
								</Typography>
							))}
						</ol>
					</React.Fragment>
				))}
			</ul>
		)
	}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={Section1Styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
					>
						<Typography color="black" sx={{ opacity: '87%' }}>
							How can you draw inspiration from the random
							elements below?
						</Typography>
						<InfoPopper
							handleClick={handleClickInfoIcon}
							anchorEl={anchorEl}
							popperContent={popperContent}
						/>
					</Box>
					<Grid item sx={BaseStyles.section}>
						<Box sx={BaseStyles.containerai}>
							{currentPrompts?.map((value, index) => (
								<PromptBoxV2
									contentText={value}
									data-testid={`${TEST_ID}__prompt-box_${index}`}
									key={`${TEST_ID}-prompt-box-key-${index}`}
									loading={isLoading}
									hidePromptContainerStyle={true}
									gameTypeEnum={GAME_TYPE.LEAP_FROG}
								/>
							))}
							<Grid
								item
								flexGrow={1}
								display={'flex'}
								flexDirection={'row'}
								justifyContent={'center'}
								alignItems={'center'}
								gap={'1rem'}
								height={100}
							>
								<ButtonWithIconComponent
									sx={{ padding: 0 }}
									disabled={
										generatePromptsDisabled() || isLoading
									}
									iconSvg={refreshIcon}
									text="REFRESH"
									data-testid={`${TEST_ID}__refresh-button`}
									onClickAction={handleRandomPrompts}
								/>
							</Grid>
						</Box>
					</Grid>
					<Typography color={'black'}>
						{brainstormText.global.ideasDropdown}
					</Typography>
					<GeneratedExampleDropdown
						isLoading={isAiLoading}
						gameTypeEnum={GAME_TYPE.LEAP_FROG}
						headerText={''}
						customResponse={buildResponseItem(currentResponses)}
					/>
				</Box>
			</Box>
		</>
	)
}

export default Section2
