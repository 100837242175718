import React, { ReactNode, forwardRef, useMemo } from 'react'

import KeyboardArrowDownSharp from '@mui/icons-material/KeyboardArrowDownSharp'
import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select, // SelectChangeEvent,
	SelectProps,
} from '@mui/material'

import { styles } from './BaseDropdown.styles'

export const TEST_ID = 'default-test-id-dropdown'
export const CLEAR_VALUE_OPTION = {
	label: 'Clear Value',
	value: '',
}

const createMenuItems = (
	options: (
		| string
		| { disabled?: boolean; label?: string; value?: string | number }
	)[],
	value: string | number | readonly string[],
	clearable?: boolean,
	dataTestId?: string,
	isMulti?: boolean
) => {
	const menuItems = [
		<MenuItem
			key={''}
			value={CLEAR_VALUE_OPTION.value}
			sx={styles.clearValueOption}
			data-testid={`${dataTestId || TEST_ID}__clear-value-option`}
		>
			{CLEAR_VALUE_OPTION.label}
		</MenuItem>,
	]
	return [
		...(value !== '' && clearable ? menuItems : []),
		...options.map((option) => {
			const optionValue =
				typeof option === 'string' ? option : option.value
			const label =
				typeof option === 'string'
					? option
					: option.label || option.value
			const disabled = typeof option !== 'string' && option.disabled

			return (
				<MenuItem
					disabled={disabled}
					key={optionValue}
					value={optionValue}
					data-testid={`${dataTestId || TEST_ID}-option-${optionValue}`}
				>
					{isMulti && (
						<Checkbox
							checked={(value as string[]).includes(
								`${optionValue}`
							)}
						/>
					)}
					{label || optionValue}
				</MenuItem>
			)
		}),
	]
}

type SelectOptionProps = {
	disabled?: boolean
	label?: string
	value?: string | number
}

export type Props = {
	clearable?: boolean
	'data-testid'?: string
	helperText?: string
	noMinWidth?: boolean
	options: (string | SelectOptionProps)[]
	sx?: object
	value: string | number | string[]
} & SelectProps

const BaseDropdown = forwardRef<HTMLInputElement, Props>(
	(
		{
			id,
			label,
			value,
			options,
			onChange,
			readOnly,
			error,
			required,
			disabled,
			helperText,
			'data-testid': dataTestId,
			placeholder,
			clearable,
			sx,
			noMinWidth,
			...props
		},
		ref
	) => {
		const generatedOptions = useMemo(
			() =>
				createMenuItems(
					options,
					value,
					clearable,
					dataTestId,
					props.multiple
				),
			[options, value, clearable, props.multiple, dataTestId]
		)

		const valueMap = useMemo(() => {
			return new Map(
				options.map((obj) => {
					if (typeof obj === 'string') {
						return [obj, obj]
					}

					return [obj.value, obj.label]
				})
			)
		}, [options])

		return (
			<FormControl
				data-testid={`${dataTestId || TEST_ID}-input-select-form-control`}
				error={error}
				required={required}
				disabled={disabled}
				sx={sx}
			>
				<InputLabel
					id={`${id || dataTestId || TEST_ID}-input-select-label`}
					shrink={true}
				>
					{label}
				</InputLabel>
				<Select
					labelId={`${id || dataTestId || TEST_ID}-input-select-label`}
					data-testid={`${dataTestId || TEST_ID}-input-select`}
					id={`${id || dataTestId || TEST_ID}-input-select`}
					label={label}
					value={value}
					inputProps={{
						readOnly,
						'data-testid': dataTestId || TEST_ID,
					}}
					inputRef={ref}
					IconComponent={KeyboardArrowDownSharp}
					onChange={onChange}
					displayEmpty
					renderValue={(selected: unknown): ReactNode => {
						if (!selected) return placeholder || 'Choose an option'

						let output: string | number | null = null

						if (Array.isArray(selected)) {
							return selected
								.map((item) =>
									valueMap.get(item.value ? item.value : item)
								)
								.join(', ')
						}

						for (const option of options) {
							if (
								typeof option === 'string' &&
								option === selected
							) {
								output = selected
							} else if (typeof option === 'object') {
								const { value, label }: SelectOptionProps =
									option
								if (value === selected || label === selected) {
									output =
										label || value || selected.toString()
								}
							}
						}

						return <>{output}</>
					}}
					sx={noMinWidth ? { minWidth: '0 !important' } : {}}
					{...props}
				>
					{generatedOptions}
				</Select>
				{(helperText && (
					<FormHelperText>{helperText}</FormHelperText>
				)) ||
					null}
			</FormControl>
		)
	}
)

BaseDropdown.displayName = 'BaseDropdown'

export default BaseDropdown
