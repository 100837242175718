import React, {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react'

import {
	AuthTokens,
	ConfirmResetPasswordInput,
	ResetPasswordOutput,
	confirmResetPassword,
	confirmSignIn,
	fetchAuthSession,
	getCurrentUser,
	resetPassword,
	signIn,
	signInWithRedirect,
	signOut,
} from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { authEventHandler } from 'utils/authUtils'
import logger from 'utils/logger'

import { updateInvitedUser } from 'services/email.service'

import { LOCAL_STORAGE_KEYS } from 'assets/constants'

import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import { AWS_IDENTITY_PROVIDER } from '../config'

export interface IUser {
	email: string | null
	firstName: string
	lastName?: string | null
	session?: AuthTokens
}

export interface IAuthContextType {
	guestSignIn: () => Promise<void>
	handleConfirmResetPassword: ({
		username,
		confirmationCode,
		newPassword,
	}: ConfirmResetPasswordInput) => Promise<boolean>
	handleResetPassword: (email: string) => Promise<ResetPasswordOutput>
	isAuthenticated: boolean
	isAuthenticating: boolean
	ssoSignIn: () => Promise<void>
	user: IUser
	userSignIn: (email: string, password: string) => Promise<void>
	userSignOut: () => Promise<void>
	userSignUp: (
		firstName: string,
		lastName: string,
		email: string,
		tempPassword: string,
		password: string
	) => Promise<void>
}

export const AuthContext = createContext<IAuthContextType | undefined>(
	undefined
)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<IUser>({
		email: null,
		lastName: '',
		firstName: 'login',
		session: undefined,
	})
	const [isAuthenticating, setIsAuthenticating] = useState(true)

	/**
	 * fetch currently logged-in user using AWS Auth library
	 * @returns {Promise<void>}
	 */
	const fetchAuthUser = async () => {
		try {
			await getCurrentUser()

			const { tokens: session } = await fetchAuthSession()
			const email = String(
				session?.idToken?.payload.email ?? 'guest@guest.com'
			)
			const firstName = String(
				session?.idToken?.payload.given_name ?? 'Guest'
			)
			const lastName = String(
				session?.idToken?.payload.family_name ?? 'Guest'
			)

			const fetchedUser: IUser = {
				email,
				lastName,
				firstName,
				session,
			}

			if (
				localStorage.getItem('SIGN_UP_STEP') === 'DONE' &&
				fetchedUser.lastName &&
				fetchedUser.email
			) {
				// Updates user's last name as a part of the work required to make
				// Submitted Ideas functional. This only occurs for new user signup.
				const response = await updateInvitedUser(
					fetchedUser.firstName,
					fetchedUser.lastName,
					fetchedUser.email
				)

				if (response.status === 200) {
					localStorage.removeItem('SIGN_UP_STEP')
				}
			}
			setIsAuthenticating(false)
			setUser(fetchedUser)
		} catch (error) {
			setIsAuthenticating(false)
			setUser({
				email: null,
				lastName: '',
				firstName: 'login',
				session: undefined,
			})
		}
	}

	useEffect(() => {
		fetchAuthUser()

		Hub.listen('auth', ({ payload }) => authEventHandler(payload.event))
	}, [])

	const guestSignIn = async () => {
		const username = process.env.REACT_APP_GUEST_USERNAME

		if (!username) {
			throw new Error('Guest Username must be provided')
		}

		try {
			await signIn({
				username,
				password: process.env.REACT_APP_GUEST_PASSWORD,
			})
		} catch (error) {
			logger.error('Guest Signin Error: ', error)
			throw new Error('Error occurred during Guest Sign In', error)
		}
	}

	const userSignIn = async (email: string, password: string) => {
		if (!email || !password) {
			throw new Error('User credentials must be provided')
		}

		try {
			await signIn({
				username: email,
				password,
			})
		} catch (error) {
			logger.error('User Signin Error: ', error)
			throw new Error('Error occurred during user authentication', error)
		}
	}

	const userSignUp = async (
		firstName: string,
		lastName: string,
		email: string,
		tempPassword: string,
		password: string
	) => {
		if (!firstName && !lastName && !email && !password) {
			throw new Error('New user form input invalid or missing')
		}

		try {
			const { nextStep } = await signIn({
				username: email,
				password: tempPassword,
			})

			if (
				nextStep.signInStep ===
				'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
			) {
				/**
				 * "pathname" is "/published-games/<GAME_ID>" since this is called
				 * within a modal thats opened within the published game component
				 */
				localStorage.setItem(
					LOCAL_STORAGE_KEYS.REDIRECT_PATH,
					`${window.location.pathname}?new_user=true`
				)
				const { nextStep } = await confirmSignIn({
					challengeResponse: password,
					options: {
						userAttributes: {
							given_name: `${firstName}`,
							family_name: `${lastName}`,
						},
					},
				})

				// This is a workaround to deal with the page navigation that occurs after
				// a user is authenticated. This can be removed when the auth flow is updated.
				if (nextStep.signInStep === 'DONE') {
					localStorage.setItem('SIGN_UP_STEP', nextStep.signInStep)
				}
			}
		} catch (error) {
			logger.error('UserSignUp Error: ', error)
			throw new Error('Error occurred during User Signup', error)
		}
	}

	const userSignOut = async () => signOut()

	const ssoSignIn = async () => {
		if (AWS_IDENTITY_PROVIDER !== undefined) {
			await signInWithRedirect({
				provider: { custom: AWS_IDENTITY_PROVIDER },
			})
		} else {
			logger.error('Missing AWS Provider')
		}
	}

	const handleResetPassword = async (
		username: string
	): Promise<ResetPasswordOutput> => {
		try {
			return await resetPassword({ username })
		} catch (error) {
			logger.error('ResetPassword Error: ', error)
			throw new Error('Error occurred during ResetPassword', error)
		}
	}

	const handleConfirmResetPassword = async ({
		username,
		confirmationCode,
		newPassword,
	}: ConfirmResetPasswordInput) => {
		try {
			await confirmResetPassword({
				username,
				confirmationCode,
				newPassword,
			})
			return true
		} catch (error) {
			logger.error('ConfirmResetPassword Error: ', error)
			throw new Error('Error occurred during ConfirmResetPassword', error)
		}
	}

	const value: IAuthContextType = {
		user,
		isAuthenticated: !!user && !!user.session,
		isAuthenticating,
		guestSignIn,
		userSignIn,
		userSignUp,
		userSignOut,
		ssoSignIn,
		handleResetPassword,
		handleConfirmResetPassword,
	}

	if (isAuthenticating) {
		return <LoadingSpinner />
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuth = () => {
	const context = useContext(AuthContext)
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider')
	}

	return context
}
