import { commonStyles } from 'styles/common.styles'

export const styles = {
	...commonStyles,
	container: {
		paddingTop: '3rem',
		paddingBottom: '3rem',
		paddingX: '3rem',
	},
}
