import React, { useEffect, useState } from 'react'

import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material'

import { useActivityTimeout } from '../../contexts/TimeoutProvider'
import { styles } from './TimeoutModal.styles'

export type Props = {
	timeoutDuration: number
}

export const TimeoutModal: React.FC<Props> = ({ timeoutDuration }) => {
	const {
		showTimeout,
		resetTimer,
		handleLogout,
		lastActivity,
		setShowTimeout,
	} = useActivityTimeout()
	const FIVE_MINUTES_SECONDS = 300
	const [timeLeft, setTimeLeft] = useState(FIVE_MINUTES_SECONDS) // 5 minutes in seconds

	useEffect(() => {
		let timer: NodeJS.Timeout

		if (showTimeout) {
			setTimeLeft(FIVE_MINUTES_SECONDS) // Reset to 5 minutes when modal opens
			timer = setInterval(() => {
				setTimeLeft((prev) => {
					if (prev <= 1) {
						handleLogout() // Auto logout when timer hits 0
						clearInterval(timer)
						return 0
					}
					return prev - 1
				})
			}, 1000)
		}

		return () => {
			if (timer) clearInterval(timer)
		}
	}, [showTimeout, handleLogout])

	useEffect(() => {
		const handleActivity = () => {
			if (!showTimeout) {
				resetTimer()
			}
		}

		const timeoutCheck = setInterval(() => {
			if (Date.now() - lastActivity > timeoutDuration) {
				setShowTimeout(true)
			}
		}, 1000)

		window.addEventListener('mousemove', handleActivity)
		window.addEventListener('keypress', handleActivity)
		window.addEventListener('click', handleActivity)

		return () => {
			clearInterval(timeoutCheck)
			window.removeEventListener('mousemove', handleActivity)
			window.removeEventListener('keypress', handleActivity)
			window.removeEventListener('click', handleActivity)
		}
	}, [lastActivity, timeoutDuration, showTimeout, resetTimer, setShowTimeout])

	// Format time as M:SS
	const formatTime = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60)
		const remainingSeconds = seconds % 60
		return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
	}

	return (
		<Dialog
			open={showTimeout}
			maxWidth={false}
			fullWidth
			disableEscapeKeyDown
			components={{
				Backdrop: ({ children }) => (
					<Box sx={styles.backdropBox}>{children}</Box>
				),
			}}
			PaperProps={{
				sx: styles.paperProps,
			}}
			data-testid="test-timeout-modal"
		>
			<DialogContent sx={styles.modal}>
				<Typography
					variant="h6"
					sx={styles.logoutImpending}
					data-testid="timeout-title"
				>
					Logout Impending
				</Typography>

				<Typography variant="body2" data-testid="timeout-message">
					It looks like you haven&apos;t been active on Brainstorm for
					the last 25 minutes. For your security, we will log you out
					in 5 minutes. Any un-submitted ideas will be lost.
				</Typography>

				<Typography variant="h4" data-testid="timeout-timer">
					{formatTime(timeLeft)}
				</Typography>

				<Box sx={styles.buttonBox}>
					<Button
						variant="contained"
						onClick={resetTimer}
						fullWidth
						sx={styles.stayButton}
						data-testid="stay-logged-in-btn"
					>
						STAY LOGGED IN
					</Button>

					<Button
						variant="outlined"
						onClick={handleLogout}
						fullWidth
						sx={styles.logoutButton}
						data-testid="logout-btn"
					>
						LOGOUT NOW
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
