import React, { useState } from 'react'

import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Link,
	Portal,
	Typography,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'

import userEducationImage from 'assets/images/userEducationModal.svg'

import { styles } from './UserEducationModal.styles'

const UserEducationModal = () => {
	const isOpenDefault = !sessionStorage.getItem('dontShowEdu')

	const [isOpen, setIsOpen] = useState(isOpenDefault)
	const [dontShow, setDontShow] = useState(true)

	const handleClose = () => {
		setIsOpen(false)
		sessionStorage.setItem('dontShowEdu', JSON.stringify(dontShow))
	}

	const handleSetDontShow = () => {
		setDontShow(!dontShow)
	}

	const dialogContent = () => {
		return (
			<Box data-testid="user-edu-content">
				<Typography variant="h6" sx={styles.h6}>
					Before You Start Ideating, Remember:
					<br /> In Brainstorm, the GenAI Prompts You
				</Typography>
				<br />
				<Box sx={styles.imageContainer}>
					<img
						alt="Human Ideas lead to AI Prompts"
						src={userEducationImage as unknown as string}
					/>
				</Box>
				<br />
				<Typography variant="body1" sx={styles.body1}>
					Unlike standard GenAI tools, your role is not to just curate
					ideas that GenAI provides.
				</Typography>
				<br />
				<Typography variant="body1" sx={styles.body1}>
					Instead, GenAI acts as a facilitator reframing the problem
					through different (sometimes seemingly random) perspectives
					to spark your creativity.
				</Typography>
				<br />
				<Typography variant="body1" sx={styles.body1}>
					Your role is to document your own ideas based on the prompts
					provided by GenAI.
				</Typography>
				<br />
				<FormControlLabel
					control={<Checkbox checked={dontShow} />}
					label="Don't show this again"
					onClick={handleSetDontShow}
					sx={styles.label}
				/>
				<Box sx={styles.buttonContainer}>
					<Button
						variant="contained"
						sx={styles.button}
						color="secondary"
						onClick={handleClose}
						data-testid="user-edu-close"
					>
						I Understand
					</Button>
					<Button
						component={Link}
						variant="outlined"
						sx={styles.button}
						color="secondary"
						href="https://www.slalom.com/us/en/services/artificial-intelligence/brainstorm/support"
						target="_blank"
						data-testid="user-edu-secondary"
					>
						Tell Me More
					</Button>
				</Box>
			</Box>
		)
	}
	return (
		<>
			{isOpenDefault && (
				<Portal
					container={() => document.getElementsByTagName('main')[0]!}
				>
					<Box data-testid="user-edu-modal-container">
						<Dialog
							data-testid="user-edu-dialog"
							onClose={handleClose}
							open={isOpen}
							sx={styles.dialog}
						>
							{dialogContent()}
						</Dialog>
					</Box>
				</Portal>
			)}
		</>
	)
}

export default UserEducationModal
