import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'

export type Conversation = {
	content: string
	role: string
}

export type PromptResponse = {
	companyName: string
	crossCompanyName: string
	prompts: string[]
	resetPreviousCrossCompanys: boolean
}
export function MusicalChairsModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [businessComparisons, setBusinessComparisons] = useState<string[]>([
		'',
		'',
		'',
		'',
	])
	const [businessComparisonsHasChanged, setBusinessComparisonsHasChanged] =
		useState<boolean[]>([false, false, false, false])
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [crossCompanyName, setCrossCompanyName] = useState<string>('')
	const [currentResponses, setCurrentResponses] = useState<string[]>([
		'',
		'',
		'',
		'',
	])
	const [gameId, setGameId] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')
	const [previousCrossCompanyNames, setPreviousCrossCompanyNames] = useState<
		string[]
	>([])
	const [promptConversation, setPromptConversation] = useState<
		Conversation[]
	>([])
	const [responseConversation, setResponseConversation] = useState<
		Conversation[][]
	>([[], [], [], []])
	const [termsAgreed, setTermsAgreed] = useState(false)

	const gameTypeId = GAME_TYPE.MUSICAL_CHAIRS.value

	const setPromptResponse = (response: PromptResponse) => {
		setCrossCompanyName(response.crossCompanyName)
		setPreviousCrossCompanyNames([
			response.crossCompanyName,
			...(response.resetPreviousCrossCompanys
				? []
				: previousCrossCompanyNames),
		])
		setBusinessComparisons(response.prompts)
	}

	const generateScenarioDisabled = () => {
		return (
			!innovationTopic.length ||
			!innovationCompany.length ||
			!companyProblem.length
		)
	}

	const generateNewScenarioDisabled = () => {
		return (
			generateScenarioDisabled() ||
			!crossCompanyName ||
			!businessComparisons[0].length ||
			!businessComparisons[1].length ||
			!businessComparisons[2].length ||
			!businessComparisons[3].length
		)
	}

	const submitIdeasDisabled = () => {
		if (
			generateScenarioDisabled() ||
			!authorIdeation ||
			(gameId && !termsAgreed)
		)
			return true
		return false
	}

	const promptToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
			conversation: promptConversation,
		}
	}

	const responseToJSON = (i: number) => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
			crossCompanyName,
			prompt: businessComparisons[i],
			conversation: responseConversation[i],
		}
	}

	const resetSoft = () => {
		setCrossCompanyName('')
		setResponseConversation([[], [], [], []])
		setAuthorIdeation('')
		setCurrentResponses(['', '', '', ''])
		setBusinessComparisons(['', '', '', ''])
		setBusinessComparisonsHasChanged([false, false, false, false])
	}

	const reset = () => {
		setPromptConversation([])
		resetSoft()
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}

	return {
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		promptConversation,
		setPromptConversation,
		innovationTopic,
		setInnovationTopic,
		setPromptResponse,
		crossCompanyName,
		businessComparisons,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptToJSON,
		responseToJSON,
		resetSoft,
		submitIdeasDisabled,
		gameToJSON,
		reset,
		gameId,
		setGameId,
		businessComparisonsHasChanged,
		setBusinessComparisonsHasChanged,
		termsAgreed,
		setTermsAgreed,
	}
}
