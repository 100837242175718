import React from 'react'

import ArrowForward from '@mui/icons-material/ArrowForward'
import {
	Box,
	Button,
	Grid,
	Link,
	Typography,
	useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import SealAndLogo from 'assets/images/Group 264.svg'
import { FAQ_LINK, PRIVACY_POLICY, SLALOM_LOGO_LINK } from 'assets/routes'

import { styles } from './Footer.styles'

export const TEST_ID = 'footer'

const CopyrightContainer = ({ style }: { style: React.CSSProperties }) => (
	<Grid
		data-testid={`${TEST_ID}-copyright`}
		item
		xs={12}
		lg={3}
		md={2}
		sx={style}
	>
		<Grid item>
			<Link
				color="inherit"
				data-testid={`${TEST_ID}-faq-link`}
				href={FAQ_LINK}
				target="_blank"
			>
				<Typography sx={styles.platformTypography} variant="body2">
					Platform FAQs & Support
				</Typography>
			</Link>
		</Grid>
		<Grid
			item
			sx={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Typography
				data-testid={`${TEST_ID}-copyright-text`}
				variant="body2"
				mr={1}
				sx={styles.responsiveTextSize}
			>
				{`©${new Date().getFullYear()} Slalom, Inc`}
			</Typography>
			<Link
				color="inherit"
				data-testid={`${TEST_ID}-privacy-policy-link`}
				href={PRIVACY_POLICY}
				target="_blank"
			>
				<Typography sx={styles.responsiveTextSize} variant="body2">
					Privacy Policy
				</Typography>
			</Link>
		</Grid>
	</Grid>
)

const CenterContainer = ({ style }: { style: React.CSSProperties }) => (
	<Grid data-testid={`${TEST_ID}-center`} item md={6} sx={style}>
		<Box
			data-testid={`${TEST_ID}-cta`}
			sx={
				(style as { CallToActionContainer: React.CSSProperties })
					.CallToActionContainer
			}
		>
			<Typography
				data-testid={`${TEST_ID}-cta-text`}
				sx={styles.CallToActionText}
				variant="body2"
			>
				Need a live brainstorming session or quick prototypes of your
				ideas to test their viability?
			</Typography>
			<Button
				color="secondary"
				data-testid={`${TEST_ID}-cta-button`}
				endIcon={
					<ArrowForward
						color="secondary"
						data-testid={`${TEST_ID}-cta_button-arrow`}
					/>
				}
				href="https://go.slalom.com/brainstorm-support"
				target="_blank"
				sx={styles.ActionLink}
				variant="text"
			>
				Let&rsquo;s Connect
			</Button>
		</Box>
	</Grid>
)

const LogoContainer = ({ style }: { style: React.CSSProperties }) => (
	<Grid data-testid={`${TEST_ID}-logos`} item md={3} sx={style}>
		<Box data-testid={`${TEST_ID}-logos-text`}>
			<Typography sx={styles.responsiveTextSize} variant="body2">
				Brought to you by:
			</Typography>
			<Typography sx={styles.responsiveTextSize} variant="body2">
				Slalom&rsquo;s ARC Labs
			</Typography>
		</Box>
		<Box sx={styles.LogoImages}>
			<Link
				href={SLALOM_LOGO_LINK}
				color="inherit"
				target="_blank"
				data-testid={`${TEST_ID}-logos-img`}
			>
				<img alt="Slalom Logo" src={SealAndLogo as unknown as string} />
			</Link>
		</Box>
	</Grid>
)

const Footer = () => {
	const theme = useTheme()
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
	const platform = isDesktop ? 'Desktop' : 'Mobile'

	if (isDesktop) {
		return (
			<Grid
				container
				data-testid={`${TEST_ID}-desktop`}
				sx={styles.FooterContainer}
			>
				<CopyrightContainer
					style={
						styles[
							`Copyright${platform}Container`
						] as React.CSSProperties
					}
				/>
				<CenterContainer
					style={styles[`CenterFooter${platform}Group`]}
				/>
				<LogoContainer
					style={
						styles[
							`Logos${platform}Container`
						] as React.CSSProperties
					}
				/>
			</Grid>
		)
	} else {
		return (
			<Grid
				container
				data-testid={`${TEST_ID}-mobile`}
				sx={styles.FooterContainer}
			>
				<CenterContainer
					style={styles[`CenterFooter${platform}Group`]}
				/>
				<LogoContainer
					style={
						styles[
							`Logos${platform}Container`
						] as React.CSSProperties
					}
				/>
				<CopyrightContainer
					style={
						styles[
							`Copyright${platform}Container`
						] as React.CSSProperties
					}
				/>
			</Grid>
		)
	}
}

export default Footer
