import { memo } from 'react'

import {
	ChangesToPolicy,
	GeneralPolicies,
	InfoWeCollect,
	UsesOfInfo,
} from './GeneralPolicies'

export const ChangesToPolicyCopy = memo(ChangesToPolicy)
export const GeneralPoliciesCopy = memo(GeneralPolicies)
export const InfoWeCollectCopy = memo(InfoWeCollect)
export const UsesOfInfoCopy = memo(UsesOfInfo)
