export const styles = {
	buttonsContainer: {
		gap: '2rem',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #388A12 0%, #A8E063 100%)',
	},
}
