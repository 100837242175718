export const GeneralPolicies = () => {
	return (
		<div id="generalPolicy" data-testid="generalPolicy">
			<p>
				This privacy policy (&quot;
				<b>Brainstorm Privacy Policy</b>&quot;) is issued by Slalom,
				Inc., dba Slalom Consulting, and its subsidiaries (&quot;
				<b>Slalom</b>&quot;, &quot;
				<b>we</b>&quot; or &quot;<b>us</b>&quot;) with respect to the{' '}
				<u>Brainstorm platform</u> (&quot;
				<b>Brainstorm</b>&quot;). Brainstorm is a collaborative
				generative AI powered idea sharing tool where users can play
				games to submit ideas and review ideas generated by Brainstorm.
				You will know what personal data you submit to Brainstorm
				because you control what information you input. Brainstorm
				output (text and images) is generated based on probabilistic
				methods and is not factual.{' '}
			</p>
			<p>
				This policy (together with our{' '}
				<a href="https://www.slalom.com/us/en/legal/cookie-policy">
					Cookies Policy
				</a>{' '}
				and any other documents referred to on it) sets out the basis on
				which any personal data we collect from you through Brainstorm
				will be processed by us.
			</p>
			<p>
				<i>
					Residents of the European Economic Area, the United Kingdom,
					or Switzerland, see our EEA+ Supplemental Data Protection
					Law Disclosures to this policy{' '}
					<a href="#eeaDisclosures">here</a>.{' '}
				</i>
			</p>
			<p>
				<i>
					Residents of Japan, see our Japan Supplemental Data
					Protection Law Disclosures to this policy{' '}
					<a href="#japanDisclosures">here</a>.{' '}
				</i>
			</p>
			<p>
				<i>
					California residents, see our California Consumer Privacy
					Act &quot;At-Collection&quot; Notice{' '}
					<a href="#californiaPrivacyNotice">here</a>.
				</i>
			</p>
			<p>
				<i>
					Residents of Mexico, see our Mexico Supplemental Data
					Protection Law Disclosures to this policy{' '}
					<a href="#mexicoDisclosures">here</a>.
				</i>
			</p>
			<p>
				Please read the following carefully to understand our views and
				practices regarding your personal data and how we will treat it.
				By using Brainstorm, you are accepting and consenting to the
				practices described in this policy.{' '}
			</p>
			<p>
				Your data will be collected and controlled by Slalom, Inc. (a US
				corporation incorporated under the laws of the State of Delaware
				and based at 255 S. King Street, Suite 1800, Seattle, WA 98104)
				who will share that data with companies in the Slalom group
				(&quot;
				<b>Relevant Slalom Company</b>&quot;) from time to time for the
				purposes set out in this privacy policy and/or with a Slalom
				company to which the Brainstorm idea submitted relates. For
				example, if you submit an idea in the UK, then your idea and any
				personal data related to that may be shared with Slalom
				Consulting Limited (a UK incorporated company) for these
				purposes. In such event both Slalom, Inc. and the Relevant
				Slalom Company may be considered controllers of your data under
				applicable law (for example, the General Data Protection
				Regulation 2016/679 and its implementing national legislation
				and the Data Protection Act 2018 (the &quot;
				<b>Privacy Legislation</b>&quot;)). We and the Relevant Slalom
				Company may also share your personal data with our other
				subsidiaries and within the Slalom group from time to time for
				the purposes set out in this privacy policy.
			</p>
		</div>
	)
}

export const InfoWeCollect = () => {
	return (
		<div id="infoWeCollect" data-testid="infoWeCollect">
			<p>
				<b>Information we collect from you</b>
			</p>
			<p>
				We will collect and process the following data about you:{' '}
				<ul>
					<li>
						<b>Information you give to us.</b> This is information
						about you that you give us by using and submitting ideas
						to Brainstorm. The information you give us may include
						your e-mail address, the company and business problems
						you’re ideating about, your ideas on how to solve those
						business problems, and other information that you choose
						to provide. Additional information may include
						identifiers, electronic network activity information,
						geolocation data, opinions, perceptions, and feedback,
						and other information that identifies, relates to,
						describes, or is capable of being associated with you.
						Providing such personal data is voluntary. However,
						without providing some personal data, you will be unable
						to use Brainstorm (e.g. email address is required for
						account creation). You will know what personal data you
						provide because you will actively submit it to us, and
						the personal data will vary depending on the prompts you
						input to Brainstorm.
					</li>
					<br />
					<li>
						<b>Information we collect about you.</b> In addition to
						the personal data that you actively provide, for Slalom
						to monitor and maintain the performance of Brainstorm
						and to analyze usage and activities in connection with
						Brainstorm, we may automatically collect, process and
						store certain information from you:{' '}
						<ul>
							<li>
								technical information, including the domain and
								host name from which you access the Internet,
								the Internet protocol (IP) address used to
								connect your computer to the Internet, browser
								type and version, time zone and country setting,
								browser plug-in types and versions, operating
								system and platform; and
							</li>
							<li>
								information about your visit, including the full
								Uniform Resource Locators (URL), clickstream to,
								through and from our site (including date and
								time), services you viewed or searched for, page
								response times, download errors, length of
								visits to certain pages, page interaction
								information (such as scrolling, clicks, and
								mouse-overs), and methods used to browse away
								from the page.
							</li>
						</ul>
					</li>
				</ul>
			</p>
		</div>
	)
}

export const UsesOfInfo = () => {
	return (
		<div id="usesOfInfo" data-testid="usesOfInfo">
			<p>
				<b>Uses made of the information</b>
			</p>
			<p>
				When personal information is processed, all technologies
				available to Slalom and its partners and service providers is
				used, which may include generative AI tools. We use information
				held about you in the following ways:
				<ul>
					<li>
						<b>Information you give to us.</b> We will use this
						information:
						<ul>
							<li>
								to carry out our obligations arising from any
								contracts entered into between you and us and to
								provide you with the information, products and
								services that you request from us;
							</li>
							<li>
								to provide you with information about other
								goods and services we offer that are similar to
								those that you have already purchased or
								inquired about;
							</li>
							<li>
								to provide you, or permit selected third parties
								to provide you, with information about goods or
								services we feel may interest you. We will only
								contact you by electronic means (e-mail or SMS)
								with information about goods and services
								similar to those which were the subject of a
								previous sale or negotiations of a sale to you,
								or where you have consented to this, or where we
								can otherwise lawfully do so.
							</li>
							<li>
								to notify you about changes to our service; and
							</li>
							<li>
								to ensure that content from Brainstorm is
								presented in the most effective manner for you
								and for your computer.
							</li>
							<li>
								To keep a record of acknowledgment of and
								consent to this notice to document our
								compliance with law and for short-term,
								transient use.
							</li>
						</ul>
					</li>
					<br />
					<li>
						<b>Information we collect about you.</b> We will use
						this information:
						<ul>
							<li>
								to administer Brainstorm and for internal
								operations, including troubleshooting, data
								analysis, testing, research, and statistical
								purposes;
							</li>
							<li>
								to improve Brainstorm to ensure that content is
								presented in the most effective manner for you
								and for your computer;
							</li>
							<li>
								as part of our efforts to keep Brainstorm safe
								and secure; and
							</li>
							<li>
								to make suggestions and recommendations to you
								and other users of Brainstorm about goods or
								services that may interest you or them.
							</li>
						</ul>
					</li>
					<br />
					<li>
						<b>Information we receive from other sources. </b>
						We will combine this information with information you
						give to us and information we collect about you. We will
						use this information and the combined information for
						the purposes set out above (depending on the types of
						information we receive).
					</li>
				</ul>
			</p>
		</div>
	)
}

export const ChangesToPolicy = () => {
	return (
		<div id="changesToPolicy" data-testid="changesToPolicy">
			<p>
				<b>Changes to Brainstorm Privacy Policy</b>
			</p>
			<p>
				We may make changes to this notice, including material changes,
				from time to time. Review this notice each time you use
				Brainstom to learn of any changes.
			</p>
			<p>Effective Date: October 1, 2024</p>
		</div>
	)
}
