export const ContactAndComplaints = () => {
	return (
		<div id="contactComplaints" data-testid="contactComplaints">
			<p>
				<b>Contact and Complaints</b>
			</p>
			<p>
				Questions, comments or requests regarding this Privacy Policy
				are welcomed and should be addressed to{' '}
				<a href="mailto:privacy@slalom.com">privacy@slalom.com</a> or by
				mail at Slalom, Inc., 255 S. King Street, Suite 1800, Seattle,
				WA 98104. Similarly, if you have any complaints about our
				privacy management practices, you can contact us at the same
				details. You may also have a right to raise your complaint with
				the relevant privacy regulator in your jurisdiction.
			</p>
		</div>
	)
}

export const ContactUs = () => {
	return (
		<div id="contactUs" data-testid="contactUs">
			<p>
				<b>Contact Us</b>
			</p>
			<p>
				If you have any questions or comments about these disclosures or
				our practices, please contact us at:
			</p>
			<p>
				Email address:{' '}
				<a href="mailto:privacy@slalom.com">privacy@slalom.com</a>
				<br />
				Postal address: <br />
				Slalom, Inc. <br />
				255 S. King Street, Suite 1800 <br />
				Seattle, WA 98104 <br />
				United States
			</p>
			<p>
				The title and contact information of the privacy officer in
				Quebec is:{' '}
			</p>
			<p>
				Title: VP, Information Security & Governance <br />
				Email address:{' '}
				<a href="mailto:privacy@slalom.com">privacy@slalom.com</a>
			</p>
			<p>
				Your requests will be evaluated pursuant to applicable laws.
				Upon request, we can inform you about: (i) the information we
				may need to receive from you in order to identify yourself as
				well as the documents that you may need to enclose alongside
				your request; (ii) the time periods in which you will receive an
				answer from us regarding your request; (iii) how you may file
				your request, including any templates or mechanism that may be
				available (if any); (iv) the media format in which we will
				deliver the information to you (e.g. by email, CD, etc.)
			</p>
			<p>Last Updated: October 1, 2024</p>
		</div>
	)
}
