import React, { Dispatch, SetStateAction } from 'react'

import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'

import { RichTextField } from 'components/Form'
import { GeneratedExampleDropdown } from 'components/GeneratedExampleDropdown/GeneratedExampleDropdown'
import IdeaFlameMotivator, {
	useRichTextToIdeas,
} from 'components/IdeaFlameMotivator/IdeaFlameMotivator'

import { brainstormText } from 'assets/brainstormText'

import { GameType } from 'enums/GameTypeEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './IdeationSection.styles'

export const TEST_ID = 'battle-ships-ideation-section'

export type Props = {
	authorIdeation: string[]
	cautionText?: string
	currentResponses: string[][]
	gameId?: string
	gameTypeEnum: GameType
	handleChange: (i: number) => (val: string) => void
	handleSubmitIdeas: () => void
	isLoading: boolean
	setTermsAgreed: Dispatch<SetStateAction<boolean>>
	submitIdeasDisabled: () => boolean
	termsAgreed: boolean
	viewSubmittedIdeas: () => void
}

const olStyle = {
	paddingLeft: '2rem',
}

const IdeationSection = ({
	gameTypeEnum,
	authorIdeation,
	isLoading,
	handleChange,
	submitIdeasDisabled,
	handleSubmitIdeas,
	viewSubmittedIdeas,
	currentResponses,
	cautionText,
	setTermsAgreed,
	termsAgreed,
	gameId,
}: Props) => {
	const richTextToIdeasFns = [
		useRichTextToIdeas(),
		useRichTextToIdeas(),
		useRichTextToIdeas(),
	]

	function handleCheckbox() {
		setTermsAgreed(!termsAgreed)
	}
	return (
		<>
			<Typography marginY={'2rem'} variant={'overline'}>
				3. IDEATION
			</Typography>
			<Box
				display={'flex'}
				sx={{
					'.ProseMirror': {
						border: '1.5px solid #0000003B',
						borderBottomLeftRadius: '4px',
						borderBottomRightRadius: '4px',
					},
					'.ProseMirror-focused': {
						border: '2.5px solid #0000003B',
						borderBottomLeftRadius: '4px',
						borderBottomRightRadius: '4px',
					},
				}}
			>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					flexGrow="1"
					gap="2rem"
				>
					<Box
						display="flex"
						gap={'32px'}
						sx={{
							flexDirection: {
								lg: 'row',
								md: 'row',
								sm: 'column',
								xs: 'column',
							},
						}}
					>
						{currentResponses.map(
							(
								_d, // we need the item '_d' here even if it is not used.
								i
							) => (
								<Box
									display="flex"
									key={`${TEST_ID}__prompt-box_${i}`}
									data-testid={`${TEST_ID}__prompt-box_${i}`}
									flexDirection={'column'}
									gap={'16px'}
									sx={{
										width: '100%',
									}}
								>
									<ol start={i + 1} style={olStyle}>
										<Typography>
											<li>
												{
													brainstormText.battleShips
														.ideationTitles[i]
												}
											</li>
										</Typography>
									</ol>
									<Grid
										item
										sx={{
											minHeight: {
												xs: '15rem',
												sm: '20rem',
											},
										}}
										data-testid={`${TEST_ID}__ideation`}
									>
										<RichTextField
											onChange={handleChange(i)}
											placeholderText={
												brainstormText.battleShips
													.ideationPlaceholders[i]
											}
											value={authorIdeation[i] ?? ''}
											sx={{
												background: '#FFE9C9',
												borderLeft: 'none',
												borderRight: 'none',
												borderTop: 'none',
											}}
											iconColor="#45657B"
										/>
									</Grid>
									<Box>
										<IdeaFlameMotivator
											ideaCount={richTextToIdeasFns[i](
												authorIdeation[i]
											)}
											variant="BATTLE_SHIPS"
										/>
									</Box>
									<Box>
										<Typography
											fontSize={'20px'}
											color={'#000000DE'}
										>
											Unsure of how to respond to the
											prompt?
										</Typography>
										<GeneratedExampleDropdown
											isLoading={isLoading}
											gameTypeEnum={gameTypeEnum}
											headerText={''}
											currentResponses={
												currentResponses[i]
											}
										/>
									</Box>
								</Box>
							)
						)}
					</Box>
					{gameId && (
						<Box display="flex" justify-content="space-between">
							<input
								type="checkbox"
								checked={termsAgreed}
								onChange={handleCheckbox}
								data-testid={`${TEST_ID}__term_agreement_checkbox`}
							/>
							<Typography>
								I understand my submitted ideas are only visible
								to me and the game creator.
							</Typography>
						</Box>
					)}
					<Box
						display="flex"
						sx={{
							flexDirection: {
								xs: 'column',
								sm: 'row',
							},
						}}
						gap="2rem"
					>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__submit-idea-button`}
							onClick={handleSubmitIdeas}
							disabled={submitIdeasDisabled() || isLoading}
						>
							{isLoading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>SUBMIT IDEAS</Typography>
							)}
						</Button>
						<Button
							disabled={isLoading}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__view-submit-ideas-button`}
							onClick={viewSubmittedIdeas}
						>
							{isLoading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>
									VIEW ALL SUBMITTED IDEAS
								</Typography>
							)}
						</Button>
					</Box>
					<Typography color="black" sx={{ opacity: 0.6 }}>
						{cautionText}
					</Typography>
				</Box>
			</Box>
		</>
	)
}

export default IdeationSection
