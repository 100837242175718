export const styles = {
	modal: {
		display: 'flex',
		padding: '32px',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '24px',
		borderRadius: '32px',
		background: 'var(--Light-Primary-Contrast, #FFF)',
		boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.25)',
	},
	logoutButton: {
		maxWidth: '293px',
		color: 'rgb(71, 90, 112)',
		borderColor: 'rgb(71, 90, 112)',
		borderRadius: '8px',
		py: 2,
		'&:hover': {
			borderColor: 'rgb(61, 80, 102)',
			backgroundColor: 'transparent',
		},
	},
	stayButton: {
		maxWidth: '293px',
		backgroundColor: 'rgb(71, 90, 112)',
		borderRadius: '8px',
		py: 2,
		'&:hover': {
			backgroundColor: 'rgb(61, 80, 102)',
		},
	},
	buttonBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
		alignItems: 'center',
	},
	backdropBox: {
		position: 'fixed',
		inset: 0,
		backgroundColor: 'rgba(255, 255, 255, 0.4)',
		backdropFilter: 'blur(5px)',
		zIndex: -1,
	},
	paperProps: {
		borderRadius: '32px',
		width: '560px', // Fixed width
		margin: '20px', // Add some margin for mobile
		overflow: 'hidden', // Prevent scrolling
	},
	logoutImpending: {
		color: 'rgba(69, 101, 123, 1)',
		textAlign: 'center',
	},
}
