import React, { useState } from 'react'

import {
	Button,
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material'

import { useToastContext } from 'contexts/ToastProvider'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section2.styles'

export const TEST_ID = 'musical-chairs-section2'

export type Props = {
	businessComparisons: string[]
	businessComparisonsHasChanged: boolean[]
	createPrompt: () => void
	crossCompanyName: string
	currentResponses: string[]
	loading: boolean
	reloadIdeas: () => Promise<void>
}

const buildResponseItem = (solution?: string): React.ReactElement => {
	if (!solution) {
		return (
			<Grid sx={styles.responseContainer}>
				<Typography
					variant="body1"
					sx={{ ...styles.responseText }}
					data-testid={`${TEST_ID}__no-response`}
				>
					No response available
				</Typography>
			</Grid>
		)
	}

	const stringSol = solution
		.split(/(\d.)/)
		?.splice(1)
		.filter((x) => isNaN(parseInt(x)))

	if (!stringSol?.length) {
		return (
			<Grid sx={styles.responseContainer}>
				<Typography
					variant="body1"
					sx={{ ...styles.responseText }}
					data-testid={`${TEST_ID}__no-numbered-items`}
				>
					No numbered items found
				</Typography>
			</Grid>
		)
	}

	return (
		<Grid sx={styles.responseContainer}>
			<ol data-testid="gen-ai-response-list">
				{stringSol.map((el: string, i: number) => (
					<Typography
						component="li"
						key={`ai-solution-${i}`}
						variant="body1"
						sx={{ ...styles.responseText, ...styles.listItem }}
					>
						{el}
					</Typography>
				))}
			</ol>
		</Grid>
	)
}

const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [businessComparisonIndex, setBusinessComparisonIndex] = useState(0)
	const [editPromptMode, setEditPromptMode] = useState(false)
	const [isGenAiLoading, setIsGenAiLoading] = useState(false)

	const [stageValue, setStageValue] = React.useState('eliminate')
	const handleStageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStageValue((event.target as HTMLInputElement).value)
	}

	const onClickBusinessComparison = (index: number) => {
		if (editPromptMode) {
			const editedIdea = document.getElementsByTagName('textarea')
			if (editedIdea && editedIdea.length >= 1) {
				props.businessComparisons[businessComparisonIndex] =
					editedIdea[0].value
			}
		}
		setBusinessComparisonIndex(index)
	}

	const onClickRefresh = () => {
		setBusinessComparisonIndex(0)
		props.createPrompt()
	}

	const onClickEditPrompt = () => {
		setEditPromptMode(true)
	}

	const onClickCancelEditPrompt = () => {
		setEditPromptMode(false)
	}

	const onClickSaveEditPrompt = async () => {
		const lastEditedIdea = document.getElementsByTagName('textarea')
		if (lastEditedIdea && lastEditedIdea.length >= 1) {
			if (
				props.businessComparisons[businessComparisonIndex] !==
				lastEditedIdea[0].value
			) {
				props.businessComparisons[businessComparisonIndex] =
					lastEditedIdea[0].value
				props.businessComparisonsHasChanged[businessComparisonIndex] =
					true
			}
			setEditPromptMode(false)
			await props.reloadIdeas()
			if (showAlert) {
				showAlert({
					objectType: 'editPrompt',
					severity: ToastSeverity.SUCCESS,
				})
			}
		}
	}

	return (
		<>
			<Section2Title />
			<Grid container>
				<Grid item sx={styles.sideBar} />
				<Grid sx={styles.mainContainer}>
					<Grid
						item
						xs
						container
						direction="column"
						sx={styles.contentContainer}
					>
						<Grid item>
							<Typography
								data-testid={`${TEST_ID}__inspiration-text`}
							>
								How can you draw cross-industry inspiration from
								the following company?
							</Typography>
						</Grid>

						<Grid sx={styles.radioButtonSectionContainer} item>
							<RadioGroup
								aria-label="stage"
								name="stage"
								value={stageValue}
								onChange={handleStageChange}
								row
							>
								<FormControlLabel
									value="eliminate"
									control={
										<Radio
											sx={styles.radioButtonSelection}
										/>
									}
									label={
										<Typography variant="body2">
											ELIMINATE STAGE
										</Typography>
									}
								/>
								<FormControlLabel
									value="swap"
									control={
										<Radio
											sx={styles.radioButtonSelection}
										/>
									}
									label={
										<Typography variant="body2">
											SWAP STAGE
										</Typography>
									}
								/>
							</RadioGroup>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={refreshIcon}
								text="REFRESH"
								data-testid={`${TEST_ID}__refresh-button`}
								onClickAction={onClickRefresh}
							/>
						</Grid>
						<Grid item>
							<Grid
								container
								direction="row"
								id="business-comparison-ideas"
								data-testid="business-comparison-ideas"
								sx={styles.cardContainer}
							>
								{props.businessComparisons.map(
									(businessComparison, i) =>
										editPromptMode &&
										businessComparisonIndex === i ? (
											<Grid
												container
												direction="row"
												key={`edit-business-comparison-${i}`}
												sx={styles.numberedCard}
											>
												<Grid
													flexGrow={1}
													sx={styles.cardHeader}
												>
													<TextField
														data-testid={`${TEST_ID}__edit-business-comparison-${i}`}
														defaultValue={
															businessComparison
														}
														multiline
														fullWidth
														inputRef={(input) =>
															input &&
															input.focus()
														}
													/>
												</Grid>
												<Grid
													sx={
														i <
														props
															.businessComparisons
															.length -
															1
															? styles.dividerContainerVisible
															: styles.dividerContainerHidden
													}
												>
													<Divider
														orientation="horizontal"
														sx={styles.boxDivider}
													/>
												</Grid>
											</Grid>
										) : (
											<Grid
												container
												direction="row"
												key={`business-comparison-${i}`}
												sx={styles.numberedCard}
											>
												<Grid
													flexGrow={1}
													onClick={() => {
														if (props.loading)
															return
														onClickBusinessComparison(
															i
														)
													}}
													sx={{
														...styles.cardHeader,
														cursor: props.loading
															? 'default'
															: 'pointer',
													}}
													data-testid={`${TEST_ID}__business-comparison-${i}`}
												>
													<Grid
														sx={styles.fullHeight}
													>
														{props.loading ? (
															<Grid textAlign="center">
																<CircularProgress
																	data-testid={`${TEST_ID}__comparison-spinner-${i}`}
																	color="secondary"
																	sx={
																		commonStyles.circularProgress
																	}
																/>
															</Grid>
														) : (
															<Grid
																container
																alignItems={
																	'center'
																}
																data-testid={`${TEST_ID}__business-comparison-${i}`}
																sx={
																	styles.fullHeight
																}
															>
																<Grid
																	sx={
																		styles.stepNumber
																	}
																>
																	<Typography
																		sx={
																			styles.stepNumberFont
																		}
																	>
																		{i + 1}
																	</Typography>
																</Grid>
																<Grid
																	sx={
																		styles.numberedCardText
																	}
																>
																	<Typography
																		sx={
																			styles.numberedCardFont
																		}
																	>
																		{
																			businessComparison
																		}
																	</Typography>
																</Grid>
															</Grid>
														)}
													</Grid>
												</Grid>
												<Grid
													sx={
														i <
														props
															.businessComparisons
															.length -
															1
															? styles.dividerContainerVisible
															: styles.dividerContainerHidden
													}
												>
													<Divider
														orientation="horizontal"
														sx={styles.boxDivider}
													/>
												</Grid>
											</Grid>
										)
								)}
							</Grid>
						</Grid>
						<Grid item>
							{editPromptMode ? (
								<Grid sx={styles.editButtonsContainer}>
									<Button
										color="secondary"
										variant="contained"
										data-testid={`${TEST_ID}__edit-save-button`}
										onClick={async () => {
											setIsGenAiLoading(true)
											await onClickSaveEditPrompt()
											setIsGenAiLoading(false)
										}}
									>
										<Typography>SAVE CHANGES</Typography>
									</Button>
									<Button
										variant="text"
										color="secondary"
										data-testid={`${TEST_ID}__edit-cancel-button`}
										onClick={onClickCancelEditPrompt}
									>
										<Typography>CANCEL</Typography>
									</Button>
								</Grid>
							) : (
								<Grid sx={styles.editPromptContainer}>
									<ButtonWithIconComponent
										sx={{ padding: 0 }}
										disabled={props.loading}
										iconSvg={editIcon}
										text="EDIT"
										data-testid={`${TEST_ID}-edit-prompt`}
										onClickAction={onClickEditPrompt}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item>
							<Typography
								data-testid={`${TEST_ID}__apply-prompt-text`}
							>
								{brainstormText.global.ideasDropdown}
							</Typography>
							<GeneratedExampleDropdown
								gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
								headerText={''}
								isLoading={isGenAiLoading}
								customResponse={buildResponseItem(
									props.currentResponses[
										businessComparisonIndex
									]
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default Section2
