import { Box, Container, Grid } from '@mui/material'

import SubPageHeader from 'components/SubPageHeader/SubPageHeader'

import {
	ContactAndComplaintsCopy,
	ContactUsCopy,
} from './copy/ContactAndComplaints'
import { CookiesCopy } from './copy/Cookies'
import {
	CaliforniaPrivacyNoticeCopy,
	CrossBorderDisclosuresCopy,
	DisclosureOfInfoCopy,
	EEADisclosuresCopy,
	JapanDisclosuresCopy,
	MexicoDisclosuresCopy,
} from './copy/Disclosures'
import {
	ChangesToPolicyCopy,
	GeneralPoliciesCopy,
	InfoWeCollectCopy,
	UsesOfInfoCopy,
} from './copy/GeneralPolicies'

export const TEST_ID = 'legal-page'

const LegalPage = () => {
	return (
		<Box data-testid={TEST_ID}>
			<SubPageHeader
				title="Privacy Policy"
				subtitle="We value your privacy and do not sell your data to any third parties or use your data to train any GenAI models"
				backgroundGradient="linear-gradient(90deg, #9DCDF2 0%, #BDDEF6 100%)"
			/>
			<Grid
				container
				spacing={4}
				sx={{ padding: { lg: '3.2rem 9.8rem', xs: '3.2rem' } }}
			>
				<Container
					data-testid={`${TEST_ID}-container`}
					maxWidth={'lg'}
					sx={{
						paddingTop: '3rem',
						paddingBottom: '3rem',
						paddingX: '2rem',
						maxWidth: '1440px',
						fontWeight: '400',
						lineHeight: '2.5rem',
						color: 'black',
					}}
				>
					<Box>
						<GeneralPoliciesCopy />
						<br />
						<InfoWeCollectCopy />
						<CookiesCopy />
						<br />
						<UsesOfInfoCopy />
						<DisclosureOfInfoCopy />
						<CrossBorderDisclosuresCopy />
						<br />
						<ContactAndComplaintsCopy />
						<br />
						<ChangesToPolicyCopy />
						<br />
						<EEADisclosuresCopy />
						<br />
						<JapanDisclosuresCopy />
						<CaliforniaPrivacyNoticeCopy />
						<br />
						<MexicoDisclosuresCopy />
						<br />
						<ContactUsCopy />
					</Box>
				</Container>
			</Grid>
		</Box>
	)
}

export default LegalPage
