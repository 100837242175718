import { useMemo, useRef } from 'react'
import { generatePath } from 'react-router-dom'

import ChevronRight from '@mui/icons-material/ChevronRight'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PersonAdd from '@mui/icons-material/PersonAdd'
import {
	Chip,
	Divider,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useBoolean } from 'usehooks-ts'

import { Game } from 'models/gameModels'

import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

import { ERRORS_PAGE, PUBLISHED_GAME_ROUTE_TEMPLATE } from 'assets/routes'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { styles } from './CustomGameCard.styles'

export const TEST_ID = 'custom-game-card'

export interface Props {
	game: Game
	handleDeleteRequest?: (gameId: string) => void
}

export default function CustomGameCard(props: Props) {
	const { showModal } = useModalContext()
	const {
		setTrue: openMenu,
		setFalse: closeMenu,
		value: isMenuOpen,
	} = useBoolean(false)

	const optionsMenuButtonRef = useRef<HTMLButtonElement>(null)

	const { game } = props
	const { backgroundGradient, label } = Object.values(GAME_TYPE).filter(
		(type) => type.value === Number(game.gameTypeId)
	)[0]

	const chips = [
		{ label: game.innovationCompany, background: '#00000014' },
		{ label, background: backgroundGradient },
	]

	const link = useMemo(() => {
		if (!game.gameId) {
			return ''
		}

		if (game.isAuthor || game.isParticipant) {
			return generatePath(PUBLISHED_GAME_ROUTE_TEMPLATE, {
				gameId: game.gameId,
			})
		}

		return generatePath(ERRORS_PAGE)
	}, [game.gameId, game.isAuthor, game.isParticipant])

	const handleShareClick = () => {
		showModal('SHARE_MODAL', {
			gameTitle: game.gameTitle,
			gameLink: link,
			gameId: game.gameId,
		})
	}

	const handleEditClick = () => {
		showModal(MODAL_TYPES.CUSTOM_GAME_MODAL, {
			editable: true,
			gameId: game.gameId,
		})
	}

	const handleDeleteClick = () => {
		const { handleDeleteRequest } = props
		showModal(MODAL_TYPES.DELETE_PUBLISHED_GAME_MODAL, {
			handleDeleteClick: () => handleDeleteRequest?.(game.gameId),
			gameTitle: game.gameTitle,
		})
	}

	const endTime = game.endTime ? new Date(game.endTime) : new Date()

	const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
	const firstDate = Date.now()
	const secondDate = endTime.getTime()

	const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))

	return (
		<Box data-testid={TEST_ID} sx={styles.container}>
			<Box
				sx={{ ...styles.gradientBar, background: backgroundGradient }}
			></Box>
			<Box sx={styles.topRow}>
				<Box>
					{chips.map((chip) => (
						<Chip
							label={chip.label}
							variant={'filled'}
							key={chip.label}
							sx={{
								...styles.chip,
								background: chip.background,
							}}
						/>
					))}
				</Box>
				{game.isAuthor && (
					<>
						<IconButton
							id="more-options-button"
							data-testid={`${TEST_ID}-more-options-button`}
							sx={styles.iconButton}
							ref={optionsMenuButtonRef}
							onClick={openMenu}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="more-options-menu"
							data-testid={`${TEST_ID}-more-options-menu`}
							anchorEl={optionsMenuButtonRef.current}
							open={isMenuOpen}
							onClose={closeMenu}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<MenuItem
								id="edit-game-button"
								data-testid={`${TEST_ID}-edit-game-button`}
								onClick={handleEditClick}
							>
								Edit Game
							</MenuItem>
							<MenuItem
								data-testid={`${TEST_ID}-delete-game-button`}
								id="delete-game-button"
								onClick={handleDeleteClick}
							>
								Delete Game
							</MenuItem>
						</Menu>
					</>
				)}
			</Box>
			<Link href={link} style={styles.link}>
				<Box sx={styles.middleRow}>
					<Typography
						data-testid={`${TEST_ID}-game-title`}
						sx={styles.title}
					>
						{game.gameTitle}
					</Typography>
					<ChevronRight sx={styles.chevron} />
				</Box>
			</Link>
			<Divider sx={{ margin: '1.2rem' }} />
			<Box sx={styles.bottomRow}>
				<Box sx={styles.stats}>
					<Box>
						<Typography>{game.ideaCount}</Typography>
						<Typography>{'ideas'}</Typography>
					</Box>
					<Box>
						<Typography>{game.participantCount}</Typography>
						<Typography>{'participants'}</Typography>
					</Box>
					<Box>
						<Typography>
							{isNaN(diffDays) ? 0 : diffDays}
						</Typography>
						<Typography>{'days to go'}</Typography>
					</Box>
				</Box>
				<Box sx={styles.icons}>
					{game.isAuthor && (
						<>
							<IconButton
								data-testid={`${TEST_ID}-share-button`}
								sx={{ width: '25%' }}
								onClick={handleShareClick}
							>
								<PersonAdd />
							</IconButton>
						</>
					)}
				</Box>
			</Box>
		</Box>
	)
}
