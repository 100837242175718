import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Button, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { BaseTextField } from 'components/Form'

import { typography } from 'theme/typography'

import { SUBMITTED_IDEAS } from 'assets/routes'

import { styles } from './Section1.styles'

export const TEST_ID = 'round-robin-section1'

export type Props = {
	companyIdea: string
	companyName: string
	createPrompt: () => void
	editable: boolean
	generatePromptsDisabled: () => boolean
	isLoading: boolean
	navigateTo: (path: string) => void
	resetAllField: () => void
	setCompanyIdea: (val: string) => void
	setCompanyName: (val: string) => void
}

const Section1 = ({
	companyName,
	setCompanyName,
	companyIdea,
	setCompanyIdea,
	generatePromptsDisabled,
	resetAllField,
	isLoading,
	navigateTo,
	createPrompt,
	editable,
}: Props) => {
	return (
		<>
			<Typography marginY={'2rem'} variant={'overline'}>
				1. INNOVATION TOPIC
			</Typography>
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Typography color="black">
						What company are you brainstorming about today?{' '}
						<Box component="span" sx={typography.asterisk}>
							*
						</Box>
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
							required: false,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-name-input`}
						label="Enter company name here."
						value={companyName}
						onChangeValue={(val) => setCompanyName(val)}
						required
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Typography color="black">
						IMPORTANT: This game is unique in that it’s designed to
						help you refine and iterate on existing ideas. Enter an
						idea to refine below, or review your submitted ideas
						first.
					</Typography>
					<Box display="flex" flexDirection="row" gap="2rem">
						<Button
							color="secondary"
							variant="outlined"
							data-testid={`${TEST_ID}__view-submitted-ideas-button`}
							onClick={() => navigateTo(SUBMITTED_IDEAS)}
						>
							VIEW SUBMITTED IDEAS
						</Button>
					</Box>
					<Typography color="black" sx={{ opacity: '87%' }}>
						Tell us briefly about the idea you want to refine
						(within 5-10 words).{' '}
						<Box component="span" sx={typography.asterisk}>
							*
						</Box>
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
							required: false,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-idea-input`}
						label="Enter your idea here."
						onChangeValue={(val) => setCompanyIdea(val)}
						value={companyIdea}
						required
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Box display="flex" flexDirection="row" gap="2rem">
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generatePromptsDisabled() || isLoading}
							onClick={createPrompt}
						>
							START IDEATING
						</Button>
						<Button
							disabled={!editable}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAllField}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section1
