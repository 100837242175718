import { useState } from 'react'

import logger from 'utils/logger'

import { updateDressUpAdvice } from 'services/completions.service'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { DressUpPromptResponse } from './ideaModels'

type Conversation = {
	content: string
	role: string
}

export function DressUpModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')
	const [promptConversation, setPromptConversation] = useState<
		Conversation[]
	>([])
	const [responseConversation, setResponseConversation] = useState<
		Conversation[][]
	>([[], [], [], []])
	const [termsAgreed, setTermsAgreed] = useState(false)

	const gameTypeId = GAME_TYPE.DRESS_UP.value

	const [dressUpPrompt, setDressUpPrompt] = useState<DressUpPromptResponse>()
	const setPromptResponse = (response: DressUpPromptResponse) => {
		setDressUpPrompt(response)
	}

	const generateScenarioDisabled = () => {
		return (
			!innovationTopic.length ||
			!innovationCompany.length ||
			!companyProblem.length
		)
	}

	const generateNewScenarioDisabled = () => {
		return generateScenarioDisabled() || !dressUpPrompt
	}

	const submitIdeasDisabled = () => {
		if (
			generateScenarioDisabled() ||
			!authorIdeation ||
			(gameId && !termsAgreed)
		)
			return true
		return false
	}

	const promptToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
		}
	}

	const resetSoft = () => {
		setDressUpPrompt(undefined)
		setResponseConversation([[], [], [], []])
		setAuthorIdeation('')
	}

	const reset = () => {
		setPromptConversation([])
		resetSoft()
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}

	const updateCompanyAdvice = async (
		updatedTraits: string,
		companyIndex: number
	) => {
		if (!dressUpPrompt?.data?.companies) {
			return
		}

		try {
			const response = await updateDressUpAdvice({
				companyName: innovationCompany,
				crossCompanyName:
					dressUpPrompt.data.companies[companyIndex].name,
				innovationTopic,
				prompt: '',
				updatedTraits,
			})

			if (
				response?.status === 200 &&
				response?.data?.companies?.length > 0
			) {
				const updatedCompanies = [...dressUpPrompt.data.companies]
				updatedCompanies[companyIndex] = {
					...updatedCompanies[companyIndex],
					traits: updatedTraits,
					advices: response.data.companies[0].advices,
				}

				setPromptResponse({
					...dressUpPrompt,
					data: {
						...dressUpPrompt.data,
						companies: updatedCompanies,
					},
				})
			}
		} catch (error) {
			logger.error('Error updating company advice:', error)
			throw error
		}
	}

	return {
		updateCompanyAdvice,
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		promptConversation,
		setPromptConversation,
		innovationTopic,
		setInnovationTopic,
		setPromptResponse,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptToJSON,
		resetSoft,
		submitIdeasDisabled,
		gameToJSON,
		reset,
		gameId,
		setGameId,
		termsAgreed,
		setTermsAgreed,
		dressUpPrompt,
	}
}
